import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { ReportSummary } from "../models/report-management.models";
import { ReportManagementActions } from "../state/report-management.actions";

@Injectable()
export class ReportSummaryResolver implements Resolve<ReportSummary> {
    constructor(private readonly store: Store) {}

    public resolve(route: ActivatedRouteSnapshot): Promise<ReportSummary> {
        return lastValueFrom(this.store.dispatch(new ReportManagementActions.GetReportSummary(route.params?.id)));
    }
}
