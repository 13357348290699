import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, EMPTY, finalize, tap } from "rxjs";
import { UserEntity } from "../../shared/models/user.models";
import {
    ReportGeoZoneDetails,
    ReportGeoZoneInfo,
    ReportListItem,
    ReportManagementError,
    ReportManagementErrorType,
    ReportSummary,
} from "../models/report-management.models";
import { ReportManagementApiService } from "../services/report-management-api.service";
import { ReportManagementActions } from "./report-management.actions";

interface ActiveOfficersPerUnit {
    [officerUnitId: string]: UserEntity[];
}

export interface ReportManagementStateModel {
    isProcessing: boolean;
    reports: ReportListItem[] | undefined;
    reportsPage: Page | undefined;
    reportSummary: ReportSummary | undefined;
    reportManagementError: ReportManagementError | undefined;
    activeOfficers: ActiveOfficersPerUnit;
    officers: UserEntity[] | undefined;
    getActiveOfficersError: ReportManagementError | undefined;
    getOfficersError: ReportManagementError | undefined;
    updateAssignedOfficerError: ReportManagementError | undefined;
    updateOfficerUnitError: ReportManagementError | undefined;
    selectedReportGeoZonesInfo: ReportGeoZoneInfo[] | undefined;
    selectedReportGeoZonesInfoError: ReportManagementError | undefined;
    isSelectedReportGeoZonesInfoLoading: boolean;
    selectedReportGeoZoneDetails: ReportGeoZoneDetails | undefined;
    selectedReportGeoZoneDetailsError: ReportManagementError | undefined;
}

const DEFAULT_STATE: ReportManagementStateModel = {
    isProcessing: false,
    reports: undefined,
    reportsPage: undefined,
    reportSummary: undefined,
    reportManagementError: undefined,
    activeOfficers: {},
    officers: undefined,
    getActiveOfficersError: undefined,
    getOfficersError: undefined,
    updateAssignedOfficerError: undefined,
    updateOfficerUnitError: undefined,
    selectedReportGeoZonesInfo: undefined,
    selectedReportGeoZonesInfoError: undefined,
    isSelectedReportGeoZonesInfoLoading: false,
    selectedReportGeoZoneDetails: undefined,
    selectedReportGeoZoneDetailsError: undefined,
};

@State<ReportManagementStateModel>({
    name: "reportManagement",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class ReportManagementState {
    @Selector()
    public static isProcessing(state: ReportManagementStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static reports(state: ReportManagementStateModel): ReportListItem[] | undefined {
        return state.reports;
    }

    @Selector()
    public static reportsPage(state: ReportManagementStateModel): Page | undefined {
        return state.reportsPage;
    }

    @Selector()
    public static activeOfficers(state: ReportManagementStateModel): ActiveOfficersPerUnit {
        return state.activeOfficers;
    }

    @Selector()
    public static officers(state: ReportManagementStateModel): UserEntity[] | undefined {
        return state.officers;
    }

    @Selector()
    public static reportSummary(state: ReportManagementStateModel): ReportSummary | undefined {
        return state.reportSummary;
    }

    @Selector()
    public static reportManagementError(state: ReportManagementStateModel): ReportManagementError | undefined {
        return state.reportManagementError;
    }

    @Selector()
    public static hasGetListErrorOccurred(state: ReportManagementStateModel): boolean {
        return state.reportManagementError?.type === ReportManagementErrorType.GetList;
    }

    @Selector()
    public static hasGetReportSummaryErrorOccurred(state: ReportManagementStateModel): boolean {
        return state.reportManagementError?.type === ReportManagementErrorType.GetReportSummary;
    }

    @Selector()
    public static hasGetOfficersErrorOccurred(state: ReportManagementStateModel): boolean {
        return !!state.getOfficersError;
    }

    @Selector()
    public static hasGetActiveOfficersErrorOccurred(state: ReportManagementStateModel): boolean {
        return !!state.getActiveOfficersError;
    }

    @Selector()
    public static updateAssignedOfficerError(state: ReportManagementStateModel): ReportManagementError | undefined {
        return state.updateAssignedOfficerError;
    }

    @Selector()
    public static updateOfficerUnitError(state: ReportManagementStateModel): ReportManagementError | undefined {
        return state.updateOfficerUnitError;
    }

    @Selector()
    public static selectedReportGeoZonesInfo(state: ReportManagementStateModel): ReportGeoZoneInfo[] | undefined {
        return state.selectedReportGeoZonesInfo;
    }

    @Selector()
    public static selectedReportGeoZonesInfoError(state: ReportManagementStateModel): ReportManagementError | undefined {
        return state.selectedReportGeoZonesInfoError;
    }

    @Selector()
    public static isSelectedReportGeoZonesInfoLoading(state: ReportManagementStateModel): boolean {
        return state.isSelectedReportGeoZonesInfoLoading;
    }

    @Selector()
    public static selectedReportGeoZoneDetails(state: ReportManagementStateModel): ReportGeoZoneDetails | undefined {
        return state.selectedReportGeoZoneDetails;
    }

    @Selector()
    public static selectedReportGeoZoneDetailsError(state: ReportManagementStateModel): ReportManagementError | undefined {
        return state.selectedReportGeoZoneDetailsError;
    }

    constructor(private readonly reportManagementApi: ReportManagementApiService) {}

    @Action(ReportManagementActions.GetReports)
    public getReports(
        context: StateContext<ReportManagementStateModel>,
        { involvementType, queryParams }: ReportManagementActions.GetReports
    ) {
        context.patchState({ isProcessing: true, reportManagementError: undefined, reportsPage: undefined });

        return this.reportManagementApi.getReports(involvementType, queryParams).pipe(
            tap((reportList) =>
                context.patchState({
                    reports: reportList.content,
                    reportsPage: reportList.page,
                })
            ),
            catchError((error) => {
                context.patchState({ reportManagementError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(ReportManagementActions.GetReportSummary)
    public getReportSummary(context: StateContext<ReportManagementStateModel>, action: ReportManagementActions.GetReportSummary) {
        context.patchState({
            isProcessing: true,
            reportManagementError: undefined,
            selectedReportGeoZonesInfo: undefined,
        });

        return this.reportManagementApi.getReportSummary(action.reportId).pipe(
            tap((reportSummary) => context.patchState({ reportSummary })),
            catchError((error) => {
                context.patchState({ reportManagementError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(ReportManagementActions.GetActiveOfficers)
    public getActiveOfficers(
        context: StateContext<ReportManagementStateModel>,
        { officerUnitId }: ReportManagementActions.GetActiveOfficers
    ) {
        const currentActiveOfficers = context.getState().activeOfficers;

        context.patchState({ isProcessing: true, getActiveOfficersError: undefined });

        return this.reportManagementApi.getActiveOfficers(officerUnitId).pipe(
            tap((unitActiveOfficers) =>
                context.patchState({ activeOfficers: { ...currentActiveOfficers, [officerUnitId]: unitActiveOfficers } })
            ),
            catchError((error) => {
                context.patchState({ getActiveOfficersError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(ReportManagementActions.GetOfficers)
    public getOfficers(context: StateContext<ReportManagementStateModel>) {
        context.patchState({ isProcessing: true, getOfficersError: undefined });

        return this.reportManagementApi.getOfficers().pipe(
            tap((officers) => context.patchState({ officers })),
            catchError((error) => {
                context.patchState({ getOfficersError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(ReportManagementActions.UpdateAssignedOfficer)
    public updateAssignedOfficer(
        context: StateContext<ReportManagementStateModel>,
        { reportId, officerId }: ReportManagementActions.UpdateAssignedOfficer
    ) {
        context.patchState({ updateAssignedOfficerError: undefined });

        return this.reportManagementApi.updateAssignedOfficer(reportId, officerId).pipe(
            catchError((error) => {
                context.patchState({ updateAssignedOfficerError: error });

                return EMPTY;
            })
        );
    }

    @Action(ReportManagementActions.UpdateInterventionNote)
    public updateInterventionNote(
        context: StateContext<ReportManagementStateModel>,
        action: ReportManagementActions.UpdateInterventionNote
    ) {
        context.patchState({ reportManagementError: undefined });

        return this.reportManagementApi.updateInterventionNote(action.reportId, action.note).pipe(
            catchError((error) => {
                context.patchState({ reportManagementError: error });

                return EMPTY;
            })
        );
    }

    @Action(ReportManagementActions.UpdateReportStatus)
    public updateReportStatus(context: StateContext<ReportManagementStateModel>, action: ReportManagementActions.UpdateReportStatus) {
        context.patchState({ reportManagementError: undefined });

        return this.reportManagementApi.updateReportStatus(action.statusChange).pipe(
            catchError((error) => {
                context.patchState({ reportManagementError: error });

                return EMPTY;
            })
        );
    }

    @Action(ReportManagementActions.UpdateOfficerUnit)
    public updateOfficerUnit(
        context: StateContext<ReportManagementStateModel>,
        { reportId, officerUnit }: ReportManagementActions.UpdateOfficerUnit
    ) {
        context.patchState({ isProcessing: true, updateOfficerUnitError: undefined });

        return this.reportManagementApi.updateOfficerUnit(reportId, officerUnit).pipe(
            catchError((error) => {
                context.patchState({ updateOfficerUnitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(ReportManagementActions.GetReportGeoZonesInfo)
    public getReportGeoZonesInfo(context: StateContext<ReportManagementStateModel>, action: ReportManagementActions.GetReportGeoZonesInfo) {
        context.patchState({
            isSelectedReportGeoZonesInfoLoading: true,
            selectedReportGeoZonesInfoError: undefined,
        });

        return this.reportManagementApi.getReportGeoZonesInfo(action.params).pipe(
            tap((zonesInfo) => context.patchState({ selectedReportGeoZonesInfo: zonesInfo })),
            catchError((error) => {
                context.patchState({ selectedReportGeoZonesInfoError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isSelectedReportGeoZonesInfoLoading: false }))
        );
    }

    @Action(ReportManagementActions.GetReportGeoZoneDetails)
    public getReportGeoZoneDetails(
        context: StateContext<ReportManagementStateModel>,
        { zoneId }: ReportManagementActions.GetReportGeoZoneDetails
    ) {
        context.patchState({
            isSelectedReportGeoZonesInfoLoading: true,
            selectedReportGeoZoneDetails: undefined,
            selectedReportGeoZoneDetailsError: undefined,
        });

        return this.reportManagementApi.getReportGeoZoneDetails(zoneId).pipe(
            tap((zoneDetails) => context.patchState({ selectedReportGeoZoneDetails: zoneDetails })),
            catchError((error) => {
                context.patchState({ selectedReportGeoZoneDetailsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isSelectedReportGeoZonesInfoLoading: false }))
        );
    }
}
