import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import turfBooleanIntersects from "@turf/boolean-intersects";
import turfCircle from "@turf/circle";
import { Feature, Polygon, Properties } from "@turf/helpers";
import { GeoJSON } from "leaflet";
import { catchError, EMPTY, finalize, Subscription, tap } from "rxjs";
import { Flight, FlightError, FlightEvent, FlightType } from "../models/flight.models";
import { FlightApiService } from "../services/flight-api.service";
import { FlightActions } from "./flight.actions";

interface FlightStateModel {
    isProcessing: boolean;
    flights: Flight[] | undefined;
    getFlightsError: FlightError | undefined;
    searchCircle: Feature<Polygon, Properties> | undefined;
}

const defaultState: FlightStateModel = {
    isProcessing: false,
    flights: undefined,
    getFlightsError: undefined,
    searchCircle: undefined,
};

@State<FlightStateModel>({
    name: "flight",
    defaults: defaultState,
})
@Injectable()
export class FlightState {
    private flightsUpdatesSubscription: Subscription | undefined;

    @Selector()
    public static flights(state: FlightStateModel): Flight[] | undefined {
        return state.flights;
    }

    @Selector()
    public static getFlightsError(state: FlightStateModel): FlightError | undefined {
        return state.getFlightsError;
    }

    @Selector()
    public static isProcessing(state: FlightStateModel): boolean {
        return state.isProcessing;
    }

    constructor(private readonly flightApiService: FlightApiService) {}

    @Action(FlightActions.StartFlightsUpdatesWatch)
    public startFlightsUpdatesWatch(context: StateContext<FlightStateModel>) {
        this.flightsUpdatesSubscription = this.flightApiService.startFlightsUpdatesWatch().subscribe((message) => {
            const { searchCircle } = context.getState();
            if (!searchCircle) {
                return;
            }

            const { type, body } = message;
            switch (type) {
                case FlightEvent.FlightCreated:
                    return this.addFlight(context, body);
                case FlightEvent.FlightUpdated:
                    return this.updateFlight(context, body);
                case FlightEvent.FlightRemoved:
                    return this.removeFlight(context, body.id);
            }
        });
    }

    @Action(FlightActions.StopFlightsUpdatesWatch)
    public stopFlightsUpdatesWatch(context: StateContext<FlightStateModel>) {
        this.flightsUpdatesSubscription?.unsubscribe();
    }

    @Action(FlightActions.GetFlights)
    public getFlights(context: StateContext<FlightStateModel>, { longitude, latitude, date }: FlightActions.GetFlights) {
        context.patchState({ isProcessing: true, flights: undefined, getFlightsError: undefined });

        return this.flightApiService.getFlights(longitude, latitude, date).pipe(
            tap(({ flights, circle }) =>
                context.patchState({
                    flights,
                    searchCircle: turfCircle([circle.center.longitude, circle.center.latitude], circle.radius, {
                        units: "meters",
                    }),
                })
            ),
            catchError((error) => {
                context.patchState({ getFlightsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    private addFlight(context: StateContext<FlightStateModel>, flight: Flight): void {
        const { searchCircle, flights } = context.getState();
        if (
            !searchCircle ||
            !this.isFlightWithinUserRange(searchCircle, flight) ||
            flights?.some((existingFlight) => existingFlight.id === flight.id)
        ) {
            return;
        }

        context.patchState({ flights: [...(flights ?? []), flight] });
    }

    private updateFlight(context: StateContext<FlightStateModel>, flight: Flight): void {
        const { searchCircle, flights } = context.getState();
        if (!searchCircle || !this.isFlightWithinUserRange(searchCircle, flight)) {
            return;
        }

        context.patchState({ flights: [...(flights ?? []).filter((existingFlight) => existingFlight.id !== flight.id), flight] });
    }

    private removeFlight(context: StateContext<FlightStateModel>, id: string): void {
        const { flights } = context.getState();
        if (!flights?.some((existingFlight) => existingFlight.id === id)) {
            return;
        }

        context.patchState({ flights: [...(flights ?? []).filter((existingFlight) => existingFlight.id !== id)] });
    }

    private isFlightWithinUserRange(searchCircle: Feature<Polygon, Properties>, flight: Flight): boolean {
        if (flight.type === FlightType.Checkin) {
            const [checkinCenterLng, checkinCenterLat] = (flight.routeVolume.flightArea as GeoJSON.Point).coordinates;
            const checkinCircle = turfCircle([checkinCenterLng, checkinCenterLat], flight.routeVolume.radius, { units: "meters" });

            return turfBooleanIntersects(searchCircle, checkinCircle);
        }

        return turfBooleanIntersects(searchCircle, flight.routeVolume.flightArea);
    }
}
