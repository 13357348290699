import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, Output } from "@angular/core";
import { Position } from "@dtm-frontend/shared/map";
import { LeafletMapProvider, LEAFLET_MAP_PROVIDER } from "@dtm-frontend/shared/map/leaflet";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { BaseIconOptions, Circle, LeafletMouseEvent, Map } from "leaflet";
import { RotatedMarker } from "leaflet-marker-rotation";

interface ReportUavAreaComponentState {
    markerPosition: Position | undefined;
    userPosition: Position | undefined;
}

const ICON_OPTIONS: BaseIconOptions = {
    iconUrl: "assets/images/drone.png",
    /* eslint-disable no-magic-numbers */
    iconSize: [64, 64],
    iconAnchor: [32, 28],
    /* eslint-enable */
};
const UAV_CIRCLE_OPTIONS = {
    radius: 50,
    color: "#b00020", // NOTE: #b00020 - $color-error-400
    opacity: 1,
    weight: 1,
    dashArray: "5",
    fillColor: "#b00020", // NOTE: #b00020 - $color-error-400
    fillOpacity: 0.3,
};
const USER_CIRCLE_OPTIONS = {
    color: "#007544", // NOTE: #007544- $color-status-success
    opacity: 1,
    weight: 1,
    fillColor: "#007544", // NOTE: #007544 - $color-status-success
    fillOpacity: 0.1,
};

@Component({
    selector: "uav-id-shared-lib-report-uav-area[position]",
    templateUrl: "report-uav-area.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportUavAreaComponent implements OnDestroy {
    @Input() public set position(value: Position | undefined) {
        this.localStore.patchState({ markerPosition: value });
    }
    @Input() public set userPosition(value: Position | undefined) {
        this.localStore.patchState({ userPosition: value });
        this.createOrUpdateUserMarkerCircleArea(value);
    }
    @Input() public isDraggable = true;
    @Input() public isInteractive = true;
    @Input() public uavReportRangeInMeters: number | undefined;

    @Output() protected readonly uavPositionUpdate: EventEmitter<Position> = new EventEmitter<Position>();
    @Output() protected readonly uavOutOfRange: EventEmitter<number> = new EventEmitter();

    protected readonly markerPosition$ = this.localStore.selectByKey("markerPosition");

    protected readonly iconOptions = ICON_OPTIONS;

    private map!: Map;
    private uavCircleArea!: Circle;
    private userCircleArea!: Circle;

    constructor(
        private readonly localStore: LocalComponentStore<ReportUavAreaComponentState>,
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider
    ) {
        this.localStore.setState({
            markerPosition: undefined,
            userPosition: undefined,
        });

        this.mapProvider.getMap().then((providedMap) => {
            this.map = providedMap;
            this.createOrUpdateUserMarkerCircleArea(this.localStore.selectSnapshotByKey("userPosition"));

            if (this.isDraggable) {
                this.map.on("click", this.updateMarkerPositionOnMapClick, this);
            }
        });
    }

    public ngOnDestroy() {
        this.map.off("click", this.updateMarkerPositionOnMapClick, this);
    }

    public async onMarkerInitialized(marker: RotatedMarker): Promise<void> {
        const uavPosition = this.localStore.selectSnapshotByKey("markerPosition");

        this.createOrUpdateUavMarkerCircle(uavPosition);
        this.uavPositionUpdate.emit(uavPosition);
        marker.on({
            dragstart: () => this.uavCircleArea.setStyle({ opacity: 0, fillOpacity: 0 }),
            dragend: () =>
                this.uavCircleArea.setStyle({ opacity: UAV_CIRCLE_OPTIONS.opacity, fillOpacity: UAV_CIRCLE_OPTIONS.fillOpacity }),
        });
    }

    public updateMarkerPosition(position: Position): void {
        const userPosition = this.localStore.selectSnapshotByKey("userPosition");
        const currentMarkerPosition = this.localStore.selectSnapshotByKey("markerPosition");

        if (userPosition && this.uavReportRangeInMeters) {
            const distanceBetweenUserAndReportedUav = this.map.distance(
                [userPosition.latitude, userPosition.longitude],
                [position.latitude, position.longitude]
            );
            const isOutOfRange = distanceBetweenUserAndReportedUav > this.uavReportRangeInMeters;

            if (isOutOfRange && currentMarkerPosition) {
                this.uavOutOfRange.emit(this.uavReportRangeInMeters);
                this.localStore.patchState({ markerPosition: { ...currentMarkerPosition } });

                return;
            }
        }

        this.createOrUpdateUavMarkerCircle(position);
        this.localStore.patchState({ markerPosition: position });
        this.uavPositionUpdate.emit(position);
    }

    private createOrUpdateUavMarkerCircle(position: Position | undefined): void {
        if (!position) {
            return;
        }

        if (this.uavCircleArea) {
            this.uavCircleArea.setLatLng([position.latitude, position.longitude]);

            return;
        }

        this.uavCircleArea = new Circle([position.latitude, position.longitude], UAV_CIRCLE_OPTIONS);

        this.map.addLayer(this.uavCircleArea);
    }

    private createOrUpdateUserMarkerCircleArea(position: Position | undefined): void {
        if (!position || !this.map) {
            return;
        }

        if (this.userCircleArea) {
            this.userCircleArea.setLatLng([position.latitude, position.longitude]);
        }

        this.userCircleArea = new Circle([position.latitude, position.longitude], {
            ...USER_CIRCLE_OPTIONS,
            radius: this.uavReportRangeInMeters ?? 0,
        });

        this.map.addLayer(this.userCircleArea);
    }

    private updateMarkerPositionOnMapClick(event: LeafletMouseEvent) {
        this.updateMarkerPosition({ latitude: event.latlng.lat, longitude: event.latlng.lng });
    }
}
