import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AuthActions } from "@dtm-frontend/shared/auth";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { CodeVerificationTemplateComponent } from "../../../shared/components/code-verification-template/code-verification-template.component";
import { CodeVerificationError, CodeVerificationErrorType } from "../../authorization.models";
import { AuthorizationActions } from "../../state/authorization.actions";
import { AuthorizationState } from "../../state/authorization.state";

const USER_PROFILE_URL = "/user-profile";

@UntilDestroy()
@Component({
    templateUrl: "delete-account.component.html",
    styleUrls: ["delete-account.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAccountComponent {
    @ViewChild(CodeVerificationTemplateComponent) private readonly codeVerificationTemplateComponent:
        | CodeVerificationTemplateComponent
        | undefined;

    protected readonly isProcessing$ = this.store.select(AuthorizationState.isAccountDeletionProcessing);

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService
    ) {}

    public ionViewWillEnter() {
        this.codeVerificationTemplateComponent?.clearValue();
        this.codeVerificationTemplateComponent?.triggerResendCodeTimer();

        this.store
            .dispatch(AuthorizationActions.RequestAccountDeletion)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AuthorizationState.accountDeletionError);

                if (!error) {
                    return;
                }

                this.toastrService.error(this.translocoService.translate("uavIdClientLibAuth.deleteAccount.unknownError"));
            });
    }

    protected goToUserProfilePage(): void {
        this.router.navigateByUrl(USER_PROFILE_URL);
    }

    protected confirmAccountDeletion(code: string): void {
        this.store
            .dispatch(new AuthorizationActions.ConfirmAccountDeletion(code))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AuthorizationState.accountDeletionVerificationError);

                if (error) {
                    this.handleVerificationError(error);

                    return;
                }

                this.toastrService.success(this.translocoService.translate("uavIdClientLibAuth.deleteAccount.accountDeletedMessage"));
                this.store.dispatch(new AuthActions.Logout());
            });
    }

    protected resendVerificationCode(): void {
        this.store
            .dispatch(AuthorizationActions.ResendAccountDeletionVerificationCode)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AuthorizationState.accountDeletionVerificationError);

                if (error) {
                    this.handleResendVerificationError(error);

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("uavIdClientLibAuth.deleteAccount.resendVerificationCodeSuccessMessage")
                );
            });
    }

    private handleVerificationError(error: CodeVerificationError): void {
        let errorMessageKey: string;

        switch (error.type) {
            case CodeVerificationErrorType.InvalidCode: {
                errorMessageKey = "uavIdClientLibAuth.deleteAccount.invalidCodeError";
                break;
            }
            case CodeVerificationErrorType.CodeExpired: {
                errorMessageKey = "uavIdClientLibAuth.deleteAccount.codeExpiredError";
                break;
            }
            default: {
                errorMessageKey = "uavIdClientLibAuth.deleteAccount.codeVerificationFailedError";
            }
        }

        this.toastrService.error(this.translocoService.translate(errorMessageKey));
    }

    private handleResendVerificationError(error: CodeVerificationError): void {
        let errorMessageKey: string;

        switch (error.type) {
            case CodeVerificationErrorType.CodeExpired: {
                errorMessageKey = "uavIdClientLibAuth.deleteAccount.codeExpiredError";
                break;
            }
            default: {
                errorMessageKey = "uavIdClientLibAuth.deleteAccount.codeResendFailedError";
            }
        }

        this.toastrService.error(this.translocoService.translate(errorMessageKey));
    }
}
