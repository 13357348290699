import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { Position, SharedMapEndpoints, SharedMapUtils, SHARED_MAP_ENDPOINTS } from "@dtm-frontend/shared/map";
import { LeafletMapLayerConfig } from "@dtm-frontend/shared/map/leaflet";
import { RemoteIdService } from "@dtm-frontend/shared/map/leaflet-remote-id";
import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { WizardActions } from "@dtm-frontend/shared/ui/wizard";
import { LocalComponentStore, METERS_IN_KILOMETER } from "@dtm-frontend/shared/utils";
import { FlightState } from "@dtm-frontend/uav-identification-shared-lib/flight";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { RemoteId } from "remote-id";
import { map, of, tap } from "rxjs";
import { ReportError, ReportErrorType, ReportFormData, ReportFormWizardSteps, ReportType } from "../../models/report.model";
import { IS_POLICE_HEADQUARTERS_LAYER_ENABLED } from "../../report.tokens";
import { ReportActions } from "../../state/report.actions";
import { ReportState } from "../../state/report.state";
import { ReportStepVerificationCodeComponent } from "./report-step-verification-code/report-step-verification-code.component";

interface ReportFormWizardComponentState {
    uavPosition: Position | undefined;
    isIntervention: boolean;
    remoteIdData: Array<Partial<RemoteId.Data>>;
}

const REPORT_WIZARD_ID = "report-form-wizard";
const EMERGENCY_NUMBER = "112";
const ROLES_WITH_REPORT_AUTHORIZATION: UavIdRole[] = [UavIdRole.Civilian, UavIdRole.Officer, UavIdRole.DutyOfficer];
const INTERVENTION_ROLES: UavIdRole[] = [UavIdRole.Officer, UavIdRole.DutyOfficer];

@UntilDestroy()
@Component({
    selector: "uav-id-client-lib-report-form-wizard",
    templateUrl: "report-form-wizard.component.html",
    styleUrls: ["report-form-wizard.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportFormWizardComponent implements OnDestroy {
    @ViewChild("verificationCodeComponent") private verificationCodeComponent!: ReportStepVerificationCodeComponent;

    protected readonly ReportFormWizardSteps = ReportFormWizardSteps;
    protected readonly REPORT_WIZARD_ID = REPORT_WIZARD_ID;
    protected readonly POLICE_HEADQUARTERS_LAYER_CONFIG: LeafletMapLayerConfig = {
        type: "WMS",
        baseUrl: this.sharedMapEndpoints.geoServerEndpoint,
        options: {
            layers: "uav-identification",
            format: "image/png",
            transparent: true,
        },
    };

    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);
    protected readonly isAuthorized$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => roles?.some((role) => ROLES_WITH_REPORT_AUTHORIZATION.includes(role as UavIdRole))));
    protected readonly canIntervene$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => roles?.some((role) => INTERVENTION_ROLES.includes(role as UavIdRole))));

    protected readonly policeHeadquartersLayerConfig$ = of(this.POLICE_HEADQUARTERS_LAYER_CONFIG).pipe(
        map((config) => (this.isPoliceHeadquartersLayerEnabled ? config : undefined))
    );
    protected readonly isIntervention$ = this.localStore.selectByKey("isIntervention");
    protected readonly uavPosition$ = this.localStore.selectByKey("uavPosition");
    protected readonly isReportProcessing$ = this.store.select(ReportState.isReportProcessing);
    protected readonly userPosition$ = this.store.select(ReportState.userPosition);
    protected readonly flights$ = this.store.select(FlightState.flights);
    protected readonly reportNumber$ = this.store.select(ReportState.submittedReport).pipe(map((report) => report?.number));

    private readonly firstStep = ReportFormWizardSteps.HowToReport;
    private readonly reportStartedAt = new Date();

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(IS_POLICE_HEADQUARTERS_LAYER_ENABLED) private readonly isPoliceHeadquartersLayerEnabled: boolean,
        private readonly localStore: LocalComponentStore<ReportFormWizardComponentState>,
        private readonly remoteIdService: RemoteIdService,
        @Inject(SHARED_MAP_ENDPOINTS) private readonly sharedMapEndpoints: SharedMapEndpoints,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {
        this.store.dispatch(new WizardActions.SetActiveStep(this.REPORT_WIZARD_ID, this.firstStep));
        this.localStore.setState({
            uavPosition: undefined,
            isIntervention: false,
            remoteIdData: this.remoteIdService.getCurrentRemoteIds(),
        });
    }

    public ionViewDidLeave() {
        this.store.dispatch(new WizardActions.SetActiveStep(this.REPORT_WIZARD_ID, this.firstStep));
    }

    public ngOnDestroy(): void {
        this.store.dispatch(new WizardActions.CleanupWizard(this.REPORT_WIZARD_ID));
        this.store.dispatch(new ReportActions.ClearIdentity());
    }

    protected updateUavPosition(uavPosition: Position): void {
        const currentUavPosition = this.localStore.selectSnapshotByKey("uavPosition");

        if (!currentUavPosition || !SharedMapUtils.arePositionsEqual(currentUavPosition, uavPosition)) {
            this.localStore.patchState({ uavPosition });
            this.toastrService.clear();
        }
    }

    protected verifyOrGoToReport(reportType: ReportType): void {
        const isLoggedIn = this.store.selectSnapshot(AuthState.isLoggedIn);
        const isVerified = !!this.store.selectSnapshot(ReportState.identity)?.isVerified;

        if (isLoggedIn || isVerified) {
            this.localStore.patchState({ isIntervention: reportType === ReportType.Intervention });
            this.goToStep(ReportFormWizardSteps.UavGeolocation);

            return;
        }

        this.goToStep(ReportFormWizardSteps.VerifyIdentity);
    }

    protected verifyIdentity(phoneNumber: PhoneNumber): void {
        this.store
            .dispatch(new ReportActions.VerifyIdentity(phoneNumber))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(ReportState.reportError);

                    if (error) {
                        this.handleVerifyIdentityError(error);

                        return;
                    }

                    const identity = this.store.selectSnapshot(ReportState.identity);

                    if (identity?.isVerified) {
                        this.goToStep(ReportFormWizardSteps.UavGeolocation);

                        return;
                    }

                    this.verificationCodeComponent.resetTimer();
                    this.goToStep(ReportFormWizardSteps.VerificationCode);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected sendVerificationCode(code: string): void {
        const verificationId = this.store.selectSnapshot(ReportState.identity)?.verificationId;

        if (!verificationId) {
            return;
        }

        this.store
            .dispatch(new ReportActions.SendIdentityVerificationCode(verificationId, code))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(ReportState.reportError);

                    if (error) {
                        this.handleSendVerificationCodeError(error);

                        return;
                    }

                    this.goToStep(ReportFormWizardSteps.UavGeolocation);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected resendVerificationCode(): void {
        const verificationId = this.store.selectSnapshot(ReportState.identity)?.verificationId;

        if (!verificationId) {
            return;
        }

        this.store
            .dispatch(new ReportActions.ResendIdentityVerificationCode(verificationId))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(ReportState.reportError);

                    if (error) {
                        this.handleResendVerificationCodeError(error);

                        return;
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected submitDetailsForm(reportFormData: ReportFormData): void {
        const uavPosition = this.localStore.selectSnapshotByKey("uavPosition");

        if (!uavPosition) {
            return;
        }

        const userPosition = this.store.selectSnapshot(ReportState.userPosition);

        reportFormData.uav = { ...reportFormData.uav, longitude: uavPosition.longitude, latitude: uavPosition.latitude };
        reportFormData.reportStartedAt = this.reportStartedAt;
        reportFormData.isIntervention = this.localStore.selectSnapshotByKey("isIntervention");
        reportFormData.remoteIds = this.localStore.selectSnapshotByKey("remoteIdData");

        if (userPosition) {
            reportFormData.user = {
                latitude: userPosition.latitude,
                longitude: userPosition.longitude,
                phoneNumber: this.store.selectSnapshot(ReportState.identity)?.phoneNumber,
            };
        }

        this.store
            .dispatch(new ReportActions.SendReport(reportFormData))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(ReportState.reportError);

                    if (error) {
                        if (error.type === ReportErrorType.CannotSubmitReport) {
                            const message = this.translocoService.translate(
                                "uavIdClientLibReport.reportFormWizardDetailsStep.sendReportErrorLabel"
                            );
                            this.toastrService.error(message);
                        }

                        if (error.type === ReportErrorType.ReportAbroad) {
                            const message = this.translocoService.translate(
                                "uavIdClientLibReport.reportFormWizardDetailsStep.reportAbroadErrorLabel"
                            );
                            this.toastrService.error(message);
                        }

                        return;
                    }

                    if (reportFormData.isIntervention) {
                        this.goToStep(ReportFormWizardSteps.ThankYouPage);

                        return;
                    }

                    this.goToStep(ReportFormWizardSteps.Summary);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected callEmergencyAndFinishReport(): void {
        this.document.defaultView?.open(`tel:${EMERGENCY_NUMBER}`, "_self");
        this.goToStep(ReportFormWizardSteps.ThankYouPage);
    }

    protected goToStep(stepId: ReportFormWizardSteps | string): void {
        this.store.dispatch(new WizardActions.SetActiveStep(this.REPORT_WIZARD_ID, stepId));
    }

    protected handleUavOutOfRangeError(definedRangeInMeters: number): void {
        this.toastrService.clear();
        this.toastrService.error(
            this.translocoService.translate("uavIdClientLibReport.reportFormWizardUavGeolocationStep.uavOutOfRangeError", {
                value: definedRangeInMeters / METERS_IN_KILOMETER,
            })
        );
    }

    private handleVerifyIdentityError(error: ReportError): void {
        let errorMessageKey: string;

        switch (error.type) {
            case ReportErrorType.PhoneNumberInvalid: {
                errorMessageKey = "uavIdClientLibReport.reportFormWizardVerifyIdentityStep.phoneNumberInvalidError";
                break;
            }
            case ReportErrorType.PhoneNumberAlreadyUsed: {
                errorMessageKey = "uavIdClientLibReport.reportFormWizardVerifyIdentityStep.phoneNumberAlreadyUsedError";
                break;
            }
            default: {
                errorMessageKey = "uavIdClientLibReport.reportFormWizardVerifyIdentityStep.unknownError";
            }
        }

        const errorMessage = this.translocoService.translate(errorMessageKey);
        this.toastrService.error(errorMessage);
    }

    private handleSendVerificationCodeError(error: ReportError): void {
        let errorMessageKey: string;

        switch (error.type) {
            case ReportErrorType.VerificationCodeInvalid: {
                errorMessageKey = "uavIdClientLibReport.reportFormWizardVerificationCodeStep.verificationCodeInvalidError";
                break;
            }
            case ReportErrorType.VerificationCodeExpired: {
                errorMessageKey = "uavIdClientLibReport.reportFormWizardVerificationCodeStep.verificationCodeExpiredError";
                break;
            }
            default: {
                errorMessageKey = "uavIdClientLibReport.reportFormWizardVerificationCodeStep.unknownError";
            }
        }

        const errorMessage = this.translocoService.translate(errorMessageKey);
        this.toastrService.error(errorMessage);
    }

    private handleResendVerificationCodeError(error: ReportError): void {
        let errorMessageKey: string;

        switch (error.type) {
            case ReportErrorType.VerificationCodeExpired: {
                errorMessageKey = "uavIdClientLibReport.reportFormWizardVerificationCodeStep.verificationCodeExpiredError";
                break;
            }
            default: {
                errorMessageKey = "uavIdClientLibReport.reportFormWizardVerificationCodeStep.resendUnknownError";
            }
        }

        const errorMessage = this.translocoService.translate(errorMessageKey);
        this.toastrService.error(errorMessage);
    }
}
