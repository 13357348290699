import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChild,
} from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatestWith, Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DeviceSizeService } from "../../../../services/device-size/device-size.service";
import { WizardActions } from "../../state/wizard.actions";
import { WizardState } from "../../state/wizard.state";
import { WizardStepHeaderComponent } from "../wizard-step-header/wizard-step-header.component";

interface WizardHeaderComponentState {
    shouldHide: boolean;
    hideOnWidth: number | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-lib-wizard-header[wizardId]",
    templateUrl: "./wizard-header.component.html",
    styleUrls: ["./wizard-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WizardHeaderComponent implements OnInit, AfterViewInit, AfterContentInit {
    @ContentChildren(WizardStepHeaderComponent) public stepsComponents!: QueryList<WizardStepHeaderComponent>;
    @ViewChild("stepsContainer") private readonly stepsContainer: ElementRef<HTMLElement> | undefined;

    @Input() public wizardId!: string;
    @Input() public set hideOnWidth(value: number | undefined) {
        this.localStore.patchState({ hideOnWidth: value });
    }

    protected shouldHide$ = this.localStore.selectByKey("shouldHide");
    protected activeStepId$!: Observable<string | undefined>;
    protected activeStepIndex$!: Observable<number | undefined>;
    protected steps$: Observable<QueryList<WizardStepHeaderComponent>> | undefined;

    constructor(
        private readonly store: Store,
        private readonly deviceSizeService: DeviceSizeService,
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly localStore: LocalComponentStore<WizardHeaderComponentState>
    ) {
        this.localStore.setState({ shouldHide: false, hideOnWidth: undefined });
    }

    public ngOnInit() {
        this.activeStepId$ = this.store.select(WizardState.activeStepId(this.wizardId));
        this.activeStepIndex$ = this.activeStepId$.pipe(
            map((activeStep) => this.stepsComponents?.toArray().findIndex((step) => step.stepId === activeStep))
        );
    }

    public ngAfterViewInit() {
        this.observeOnResize();
    }

    public ngAfterContentInit() {
        this.stepsComponents.forEach((step) =>
            step.stepActivate.pipe(untilDestroyed(this)).subscribe(() => this.setActiveStep(step.stepId))
        );
        this.steps$ = this.stepsComponents.changes.pipe(startWith(this.stepsComponents));
    }

    private setActiveStep(stepId: string) {
        this.store.dispatch(new WizardActions.SetActiveStep(this.wizardId, stepId));
    }

    private observeOnResize(): void {
        if (!this.stepsContainer) {
            return;
        }

        // NOTE: we're using resizeObserverDebounceTime = 0 to prevent delays on rendering
        // localStore distinction mechanism prevents unnecessary DOM updates
        this.deviceSizeService
            .observeElementResize(this.elementRef, 0)
            .pipe(combineLatestWith(this.localStore.selectByKey("hideOnWidth")), untilDestroyed(this))
            .subscribe(([hostContainerRef, hideOnWidth]) => {
                const hostContainerBoundingRect = hostContainerRef.target.getBoundingClientRect();
                const stepsContainerBoundingRect = this.stepsContainer?.nativeElement.getBoundingClientRect();

                if (!hostContainerBoundingRect || !stepsContainerBoundingRect) {
                    return;
                }

                const shouldHide = hideOnWidth
                    ? hostContainerBoundingRect.width < hideOnWidth
                    : hostContainerBoundingRect.right < stepsContainerBoundingRect.right ||
                      hostContainerBoundingRect.height < stepsContainerBoundingRect.height;

                this.localStore.patchState({ shouldHide });
            });
    }
}
