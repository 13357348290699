import { Injectable } from "@angular/core";
import { AuthActions } from "@dtm-frontend/shared/auth";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { first, tap } from "rxjs";
import { CodeVerificationErrorType } from "../authorization.models";
import { AuthorizationActions } from "../state/authorization.actions";
import { AuthorizationState } from "../state/authorization.state";

@UntilDestroy()
@Injectable()
export class RegistrationService {
    constructor(
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {}

    public verifyRegistration(code: string) {
        const { registrationId, password } = this.store.selectSnapshot(AuthorizationState.authorizationData) ?? {};

        if (!registrationId || !password) {
            return;
        }

        this.store
            .dispatch(new AuthorizationActions.VerifyRegistration(registrationId, { code, password }))
            .pipe(
                first(),
                tap(() => {
                    const error = this.store.selectSnapshot(AuthorizationState.registrationVerificationError);

                    if (error) {
                        let errorMessageKey: string;

                        switch (error.type) {
                            case CodeVerificationErrorType.InvalidCode: {
                                errorMessageKey = "uavIdClientLibAuth.codeVerification.invalidCodeError";
                                break;
                            }
                            case CodeVerificationErrorType.CodeExpired: {
                                errorMessageKey = "uavIdClientLibAuth.codeVerification.codeExpiredError";
                                break;
                            }
                            default: {
                                errorMessageKey = "uavIdClientLibAuth.codeVerification.unknownError";
                            }
                        }

                        const errorMessage = this.translocoService.translate(errorMessageKey);
                        this.toastrService.error(errorMessage);

                        return;
                    }

                    const successMessage = this.translocoService.translate(
                        "uavIdClientLibAuth.codeVerification.codeVerifiedSuccessMessage"
                    );
                    this.toastrService.success(successMessage);

                    this.store.dispatch(new AuthActions.GoToLoginPage("/report/user-geolocation"));
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public resendVerificationCode() {
        const { registrationId } = this.store.selectSnapshot(AuthorizationState.authorizationData) ?? {};

        if (!registrationId) {
            return;
        }

        this.store
            .dispatch(new AuthorizationActions.ResendRegistrationVerificationCode(registrationId))
            .pipe(
                first(),
                tap(() => {
                    const error = this.store.selectSnapshot(AuthorizationState.registrationVerificationError);

                    if (error) {
                        let errorMessageKey: string;

                        switch (error.type) {
                            case CodeVerificationErrorType.CodeExpired: {
                                errorMessageKey = "uavIdClientLibAuth.codeVerification.codeExpiredError";
                                break;
                            }
                            default: {
                                errorMessageKey = "uavIdClientLibAuth.codeVerification.codeResendFailedError";
                            }
                        }

                        const errorMessage = this.translocoService.translate(errorMessageKey);
                        this.toastrService.error(errorMessage);

                        return;
                    }

                    const successMessage = this.translocoService.translate("uavIdClientLibAuth.codeVerification.codeResendSuccessMessage");
                    this.toastrService.success(successMessage);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
