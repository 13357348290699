import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { PushModule } from "@ngrx/component";
import { LeafletRemoteIdDataDirective } from "./components/leaflet-remote-id-layer/leaflet-remote-id-data.directive";
import { LeafletRemoteIdLayerOperatorDetailsComponent } from "./components/leaflet-remote-id-layer/leaflet-remote-id-layer-operator-details/leaflet-remote-id-layer-operator-details.component";
import { LeafletRemoteIdLayerUavDetailsComponent } from "./components/leaflet-remote-id-layer/leaflet-remote-id-layer-uav-details/leaflet-remote-id-layer-uav-details.component";
import { LeafletRemoteIdLayerDirective } from "./components/leaflet-remote-id-layer/leaflet-remote-id-layer.directive";
import { RemoteIdService } from "./services/remote-id.service";

@NgModule({
    imports: [BrowserAnimationsModule, PushModule, SharedI18nModule, SharedUiModule],
    declarations: [
        LeafletRemoteIdDataDirective,
        LeafletRemoteIdLayerDirective,
        LeafletRemoteIdLayerOperatorDetailsComponent,
        LeafletRemoteIdLayerUavDetailsComponent,
    ],
    providers: [
        RemoteIdService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmMapLeafletRemoteId",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [LeafletRemoteIdLayerDirective, LeafletRemoteIdDataDirective],
})
export class LeafletRemoteIdModule {}
