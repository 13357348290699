import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedUiModule } from "../../shared-ui.module";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "../i18n";
import { WizardContentComponent } from "./components/wizard-content/wizard-content.component";
import { WizardHeaderComponent } from "./components/wizard-header/wizard-header.component";
import { WizardStepContentComponent } from "./components/wizard-step-content/wizard-step-content.component";
import { WizardStepContentDirective } from "./components/wizard-step-content/wizard-step-content.directive";
import { WizardStepHeaderComponent } from "./components/wizard-step-header/wizard-step-header.component";
import { WizardState } from "./state/wizard.state";

@NgModule({
    declarations: [
        WizardHeaderComponent,
        WizardStepHeaderComponent,
        WizardStepContentComponent,
        WizardContentComponent,
        WizardStepContentDirective,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        NgxsModule.forFeature([WizardState]),
        LetModule,
        PushModule,
        SharedI18nModule,
        SharedUiModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibWizard",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [
        WizardHeaderComponent,
        WizardStepHeaderComponent,
        WizardStepContentComponent,
        WizardContentComponent,
        WizardStepContentDirective,
    ],
})
export class WizardModule {}
