import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { KEYCLOAK_CONFIG, OfflineCapableAuthModule, SharedAuthModule } from "@dtm-frontend/shared/auth";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedModule } from "../shared/shared.module";
import { AuthorizationRoutingModule } from "./authorization-routing.module";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { DeleteAccountComponent } from "./components/delete-account/delete-account.component";
import { EmailVerificationComponent } from "./components/email-verification/email-verification.component";
import { LoginPageComponent } from "./components/login-page/login-page.component";
import { PhoneVerificationComponent } from "./components/phone-verification/phone-verification.component";
import { RegistrationComponent } from "./components/registration/registration.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { UpdatePasswordComponent } from "./components/update-password/update-password.component";
import { IsCivilianGuard } from "./guards/is-civilian.guard";
import { IsNotLoggedInGuard } from "./guards/is-not-logged-in.guard";
import { RegistrationVerificationGuard } from "./guards/registration-verification.guard";
import { AuthorizationApiService } from "./services/authorization-api.service";
import { RegistrationService } from "./services/registration.service";
import { AuthorizationState } from "./state/authorization.state";

@NgModule({
    imports: [
        AuthorizationRoutingModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatTooltipModule,
        NgxsModule.forFeature([AuthorizationState]),
        LetModule,
        PushModule,
        ReactiveFormsModule,
        SharedAuthModule,
        SharedI18nModule,
        SharedUiModule,
        SharedModule,
        OfflineCapableAuthModule,
    ],
    declarations: [
        ChangePasswordComponent,
        DeleteAccountComponent,
        EmailVerificationComponent,
        LoginPageComponent,
        PhoneVerificationComponent,
        RegistrationComponent,
        ResetPasswordComponent,
        UpdatePasswordComponent,
    ],
    providers: [
        AuthorizationApiService,
        IsCivilianGuard,
        IsNotLoggedInGuard,
        RegistrationService,
        RegistrationVerificationGuard,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "uavIdClientLibAuth",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [UpdatePasswordComponent],
})
export class AuthorizationModule {
    public static forTest(): ModuleWithProviders<AuthorizationModule> {
        return {
            ngModule: AuthorizationModule,
            providers: [
                {
                    provide: AuthorizationApiService,
                    useValue: null,
                },
                {
                    provide: RegistrationService,
                    useValue: null,
                },
                {
                    provide: RegistrationVerificationGuard,
                    useValue: null,
                },
                {
                    provide: KEYCLOAK_CONFIG,
                    useValue: null,
                },
            ],
        };
    }
}
