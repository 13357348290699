import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { LocalComponentStore, SECONDS_IN_MINUTE, VERIFICATION_CODE_MASK } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { interval, map, Subject, take, takeUntil } from "rxjs";

interface CodeVerificationTemplateComponentState {
    timer: number | undefined;
}

const RESEND_CODE_TIMER = 30;

@UntilDestroy()
@Component({
    selector: "uav-id-client-lib-code-verification-template",
    templateUrl: "./code-verification-template.component.html",
    styleUrls: ["./code-verification-template.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CodeVerificationTemplateComponent {
    @Output() protected readonly codeVerify = new EventEmitter<string>();
    @Output() protected readonly codeResend = new EventEmitter<void>();

    protected readonly VERIFICATION_CODE_MASK = VERIFICATION_CODE_MASK;

    protected readonly timeLeft$ = this.localStore.selectByKey("timer");
    protected readonly isResendActionDisabled$ = this.timeLeft$.pipe(map((timeLeft) => timeLeft !== undefined && timeLeft > 0));

    protected readonly verificationCodeControl = new FormControl<string>("", { validators: [Validators.required], nonNullable: true });
    private readonly clearIntervalSubject = new Subject<void>();

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly localStore: LocalComponentStore<CodeVerificationTemplateComponentState>
    ) {
        this.localStore.setState({
            timer: undefined,
        });

        this.triggerResendCodeTimer();
    }

    public clearValue(): void {
        this.verificationCodeControl.reset("");
    }

    public triggerResendCodeTimer(): void {
        this.clearIntervalSubject.next();

        this.localStore.patchState({ timer: RESEND_CODE_TIMER });

        interval(1000)
            .pipe(take(RESEND_CODE_TIMER), takeUntil(this.clearIntervalSubject), untilDestroyed(this))
            .subscribe((secondsPassed) => {
                // NOTE ++secondsPassed required because interval emits 0 as a first value
                this.localStore.patchState({ timer: RESEND_CODE_TIMER - ++secondsPassed });
            });
    }

    protected submit(): void {
        this.verificationCodeControl.markAsTouched();

        if (this.verificationCodeControl.invalid) {
            return;
        }

        this.codeVerify.emit(this.verificationCodeControl.value);
    }

    protected resendVerificationCode(): void {
        this.codeResend.emit();
        this.triggerResendCodeTimer();
    }

    protected transformTimeLeft(timeLeft: number | undefined): string | null {
        if (timeLeft === undefined) {
            return null;
        }

        const minutes = String(Math.floor(timeLeft / SECONDS_IN_MINUTE)).padStart(2, "0");
        const seconds = String(timeLeft % SECONDS_IN_MINUTE).padStart(2, "0");

        return `${minutes}:${seconds}`;
    }
}
