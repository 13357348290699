import { HttpParams } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { SharedMapEndpoints, SHARED_MAP_ENDPOINTS } from "@dtm-frontend/shared/map";
import { LeafletMapLayerConfig } from "@dtm-frontend/shared/map/leaflet";
import { DialogService } from "@dtm-frontend/shared/ui";
import {
    ReportGeoZoneDialogComponent,
    ReportGeoZoneInfo,
    ReportManagementActions,
    ReportManagementState,
} from "@dtm-frontend/uav-identification-shared-lib/report";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { map, switchMap } from "rxjs";

const ROLES_WITH_EXTENDED_DETAILS = [UavIdRole.Officer, UavIdRole.DutyOfficer, UavIdRole.Admin];

@UntilDestroy()
@Component({
    selector: "uav-id-client-lib-my-report-preview",
    templateUrl: "./my-report-preview.component.html",
    styleUrls: ["./my-report-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyReportPreviewComponent {
    protected readonly hasGetReportSummaryErrorOccurred$ = this.store.select(ReportManagementState.hasGetReportSummaryErrorOccurred);
    protected readonly reportSummary$ = this.store.select(ReportManagementState.reportSummary);
    protected readonly hasExtendedDetails$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => roles?.some((role) => ROLES_WITH_EXTENDED_DETAILS.includes(role as UavIdRole))));
    protected readonly selectedReportGeoZonesInfo$ = this.store.select(ReportManagementState.selectedReportGeoZonesInfo);
    protected readonly selectedReportGeoZonesInfoError$ = this.store.select(ReportManagementState.selectedReportGeoZonesInfoError);
    protected readonly isSelectedReportGeoZonesInfoLoading$ = this.store.select(ReportManagementState.isSelectedReportGeoZonesInfoLoading);

    protected readonly REPORT_GEO_ZONES_LAYER_CONFIG: LeafletMapLayerConfig = {
        type: "WMS",
        baseUrl: this.sharedMapEndpoints.geoServerEndpoint,
        options: {
            layers: "uav-identification-admin",
            format: "image/png",
            transparent: true,
        },
    };

    constructor(
        private readonly dialogService: DialogService,
        @Inject(SHARED_MAP_ENDPOINTS) private readonly sharedMapEndpoints: SharedMapEndpoints,
        private readonly store: Store
    ) {}

    protected getSelectedZonesInfo(params: HttpParams): void {
        this.store.dispatch(new ReportManagementActions.GetReportGeoZonesInfo(params));
    }

    protected openZoneDetailsDialog(zone: ReportGeoZoneInfo): void {
        this.store
            .dispatch(new ReportManagementActions.GetReportGeoZoneDetails(zone.id))
            .pipe(
                switchMap(() =>
                    this.dialogService
                        .open(ReportGeoZoneDialogComponent, {
                            data: {
                                zone,
                                details: this.store.selectSnapshot(ReportManagementState.selectedReportGeoZoneDetails),
                                hasError: this.store.selectSnapshot(ReportManagementState.selectedReportGeoZoneDetailsError),
                            },
                        })
                        .afterClosed()
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
