import { HttpBackend, HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { SharedMapEndpoints, SHARED_MAP_ENDPOINTS } from "@dtm-frontend/shared/map";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { catchError, map, Observable, throwError } from "rxjs";
import { OfficerUnitEntity } from "../../shared/models/user.models";
import {
    InvolvementType,
    ReportGeoZoneDetails,
    ReportGeoZoneInfo,
    ReportList,
    ReportManagementErrorType,
    ReportStatusChange,
    ReportSummary,
} from "../models/report-management.models";
import { ReportManagementEndpoints, REPORT_MANAGEMENT_ENDPOINTS } from "../report.tokens";
import {
    convertGetReportGeoZoneDetailsResponseBodyToReportGeoZoneDetails,
    convertGetReportGeoZonesInfoResponseBodyToReportGeoZoneInfoList,
    convertReportListQueryParamsToReportRequestQueryParams,
    convertReportListResponseBodyToReportList,
    convertReportStatusChangeToReportStatusUpdateRequestPayload,
    convertReportSummaryResponseBodyToReportSummary,
    GetOfficersResponseBody,
    GetReportGeoZoneDetailsResponseBody,
    GetReportGeoZonesInfoResponseBody,
    ReportInterventionNoteUpdateResponseBody,
    ReportListResponseBody,
    ReportStatusUpdateResponseBody,
    ReportSummaryResponseBody,
    ReportUpdateAssignedOfficerResponseBody,
    ReportUpdateOfficerUnitResponseBody,
} from "./report-management-api.converters";

@Injectable()
export class ReportManagementApiService {
    private bypassKeycloakInterceptorHttpClient: HttpClient;

    constructor(
        private handler: HttpBackend,
        private readonly http: HttpClient,
        @Inject(REPORT_MANAGEMENT_ENDPOINTS) private readonly endpoints: ReportManagementEndpoints,
        @Inject(SHARED_MAP_ENDPOINTS) private readonly sharedMapEndpoints: SharedMapEndpoints
    ) {
        /* NOTE: Workaround to bypass keycloak interceptor that adds authorization header to every request of logged-in user.
           It's required to fix problem with CORS when requesting data from geoserver - authorization header is not allowed. */
        this.bypassKeycloakInterceptorHttpClient = new HttpClient(handler);
    }

    public getReports(involvementType: InvolvementType, queryParams: Params = {}): Observable<ReportList> {
        return this.http
            .get<ReportListResponseBody>(this.endpoints.getReports, {
                params: {
                    involvementType,
                    ...convertReportListQueryParamsToReportRequestQueryParams(queryParams),
                },
            })
            .pipe(
                map((reports) => convertReportListResponseBodyToReportList(reports)),
                catchError(() => throwError(() => ({ type: ReportManagementErrorType.GetList })))
            );
    }

    public getActiveOfficers(officerUnitId: string): Observable<GetOfficersResponseBody> {
        return this.http
            .get<GetOfficersResponseBody>(this.endpoints.getActiveOfficers, {
                params: {
                    officerUnitId,
                },
            })
            .pipe(catchError(() => throwError(() => ({ type: ReportManagementErrorType.Unknown }))));
    }

    public getOfficers(): Observable<GetOfficersResponseBody> {
        return this.http
            .get<GetOfficersResponseBody>(this.endpoints.getOfficers)
            .pipe(catchError(() => throwError(() => ({ type: ReportManagementErrorType.Unknown }))));
    }

    public updateReportStatus(statusChange: ReportStatusChange): Observable<ReportStatusUpdateResponseBody> {
        return this.http
            .put<ReportStatusUpdateResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.updateStatus, { id: statusChange.id }),
                convertReportStatusChangeToReportStatusUpdateRequestPayload(statusChange)
            )
            .pipe(catchError(() => throwError(() => ({ type: ReportManagementErrorType.Unknown }))));
    }

    public getReportSummary(reportId: string): Observable<ReportSummary> {
        return this.http.get<ReportSummaryResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getReportSummary, { reportId })).pipe(
            map((reportSummary) => convertReportSummaryResponseBodyToReportSummary(reportSummary)),
            catchError(() => throwError(() => ({ type: ReportManagementErrorType.GetReportSummary })))
        );
    }

    public updateAssignedOfficer(reportId: string, officerId: string): Observable<ReportUpdateAssignedOfficerResponseBody> {
        return this.http
            .put<ReportUpdateAssignedOfficerResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.updateAssignedOfficer, { id: reportId }),
                { officerId }
            )
            .pipe(catchError(() => throwError(() => ({ type: ReportManagementErrorType.Unknown }))));
    }

    public updateInterventionNote(reportId: string, note: string): Observable<ReportInterventionNoteUpdateResponseBody> {
        return this.http
            .put<ReportInterventionNoteUpdateResponseBody>(StringUtils.replaceInTemplate(this.endpoints.updateNote, { id: reportId }), {
                interventionNote: note,
            })
            .pipe(catchError(() => throwError(() => ({ type: ReportManagementErrorType.Unknown }))));
    }

    public updateOfficerUnit(reportId: string, officerUnit: OfficerUnitEntity): Observable<ReportUpdateOfficerUnitResponseBody> {
        return this.http
            .put<ReportUpdateOfficerUnitResponseBody>(StringUtils.replaceInTemplate(this.endpoints.updateOfficerUnit, { id: reportId }), {
                officerUnit,
            })
            .pipe(catchError(() => throwError(() => ({ type: ReportManagementErrorType.Unknown }))));
    }

    public getReportGeoZonesInfo(params: HttpParams): Observable<ReportGeoZoneInfo[]> {
        return this.bypassKeycloakInterceptorHttpClient
            .get<GetReportGeoZonesInfoResponseBody>(this.sharedMapEndpoints.geoServerEndpoint, { params })
            .pipe(
                map((response) => convertGetReportGeoZonesInfoResponseBodyToReportGeoZoneInfoList(response)),
                catchError(() => throwError(() => ({ type: ReportManagementErrorType.Unknown })))
            );
    }

    public getReportGeoZoneDetails(zoneId: string): Observable<ReportGeoZoneDetails> {
        return this.http
            .get<GetReportGeoZoneDetailsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getReportGeoZoneDetails, { zoneId }))
            .pipe(
                map((response) => convertGetReportGeoZoneDetailsResponseBodyToReportGeoZoneDetails(response)),
                catchError(() => throwError(() => ({ type: ReportManagementErrorType.Unknown })))
            );
    }
}
