import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { Store } from "@ngxs/store";
import { map, Observable } from "rxjs";

@Injectable()
export class IsNotLoggedInGuard implements CanActivate {
    constructor(private readonly router: Router, private readonly store: Store) {}

    public canActivate(): Observable<boolean> {
        return this.store.select(AuthState.isLoggedIn).pipe(
            map((isLoggedIn) => {
                if (isLoggedIn) {
                    this.router.navigateByUrl("/");
                }

                return !isLoggedIn;
            })
        );
    }
}
