import { Injectable } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { DialogService } from "@dtm-frontend/shared/ui";
import { TranslocoService } from "@ngneat/transloco";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { switchMap, takeUntil, tap } from "rxjs";
import { InterventionNoteDialogComponent } from "../components/intervention-note-dialog/intervention-note-dialog.component";
import { InterventionNoteDialogData } from "../models/report-shared.models";
import { ReportManagementActions } from "../state/report-management.actions";
import { ReportManagementState } from "../state/report-management.state";

@Injectable()
export class ReportInterventionNoteDialogService {
    constructor(
        private dialogService: DialogService,
        private store: Store,
        private translocoService: TranslocoService,
        private toastrService: ToastrService
    ) {}

    public openDialog(data: InterventionNoteDialogData): MatDialogRef<InterventionNoteDialogComponent, boolean> {
        const matDialogRef = this.dialogService.open(InterventionNoteDialogComponent, {
            data,
            disableClose: true,
        });
        const dialogComponent = matDialogRef.componentInstance;

        matDialogRef.componentInstance.submitNote$
            .pipe(
                tap(() => (dialogComponent.isNoteProcessing = true)),
                switchMap((note) => this.store.dispatch(new ReportManagementActions.UpdateInterventionNote(data.id, note))),
                tap(() => {
                    const error = this.store.selectSnapshot(ReportManagementState.reportManagementError);

                    if (error) {
                        this.toastrService.error(
                            this.translocoService.translate("uavIdSharedLibReport.interventionNoteDialog.noteUpdateUnknownError")
                        );

                        return;
                    }

                    this.toastrService.success(
                        this.translocoService.translate("uavIdSharedLibReport.interventionNoteDialog.noteUpdateSuccessMessage")
                    );
                    matDialogRef.close(true);
                }),
                tap(() => (dialogComponent.isNoteProcessing = false)),
                takeUntil(matDialogRef.afterClosed())
            )
            .subscribe();

        return matDialogRef;
    }
}
