import { ChangeDetectionStrategy, Component, Inject, Input } from "@angular/core";
import { DeviceSize, DeviceSizeService } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, StringUtils } from "@dtm-frontend/shared/utils";
import { RemoteId } from "remote-id";
import { UavAppUrls, UAV_APP_URLS } from "../../../leaflet-remote-id.tokens";
import { HEIGHT_ERROR_VALUE, POSITION_ERROR_VALUE } from "../../../services/remote-id.service";
import { convertRemoteIdPositionToDmsString } from "../../../utils/convert-remote-id-position-to-dms-string";

interface LeafletRemoteIdLayerOperatorDetailsComponentState {
    data: Partial<RemoteId.Data> | undefined;
}

@Component({
    selector: "dtm-map-leaflet-remote-id-layer-operator-details",
    templateUrl: "leaflet-remote-id-layer-operator-details.component.html",
    styleUrls: ["../leaflet-remote-id-layer-details-popup.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LeafletRemoteIdLayerOperatorDetailsComponent {
    @Input() public set data(value: Partial<RemoteId.Data> | undefined) {
        this.localStore.patchState({ data: value });
    }
    public get data() {
        return this.localStore.selectSnapshotByKey("data");
    }

    protected readonly HEIGHT_ERROR_VALUE = HEIGHT_ERROR_VALUE;
    protected readonly POSITION_ERROR_VALUE = POSITION_ERROR_VALUE;
    protected readonly convertRemoteIdPositionToDmsString = convertRemoteIdPositionToDmsString;

    protected readonly data$ = this.localStore.selectByKey("data");
    protected readonly isMobile$ = this.deviceSizeService.getSizeObservable(DeviceSize.Smartphone, DeviceSize.SmartphoneWide);

    constructor(
        private readonly deviceSizeService: DeviceSizeService,
        private readonly localStore: LocalComponentStore<LeafletRemoteIdLayerOperatorDetailsComponentState>,
        @Inject(UAV_APP_URLS) protected readonly uavAppUrls: UavAppUrls
    ) {
        this.localStore.setState({
            data: undefined,
        });
    }

    protected getOperatorProfileUrl(operatorId: string, uavAppUrls: UavAppUrls): string {
        return StringUtils.replaceInTemplate(uavAppUrls.operatorProfile ?? "", { operatorId });
    }

    protected isNotNullOrUndefined(value: unknown): boolean {
        return !FunctionUtils.isNullOrUndefined(value);
    }
}
