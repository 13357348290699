import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import { GEOCODING_ENDPOINTS, NominatimGeocodingEndpoints } from "../geocoding.tokens";
import { GeocodingErrorType } from "../state/geocoding.state";
import {
    convertGeocodingApiResponseToGeocodingCoordinates,
    GeocodingApiRequestPayload,
    GeocodingApiResponseBody,
    GeocodingResult,
    NominatimGeocodingResponseFormat,
} from "./geocoding-api.converters";

const RESPONSE_FORMAT = "jsonv2";
const COUNTRY_CODES = "pl";

@Injectable()
export class GeocodingApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(GEOCODING_ENDPOINTS) private readonly getNominatimGeocodingEndpoints: NominatimGeocodingEndpoints
    ) {}

    public getGeocodes(
        address: string,
        format: NominatimGeocodingResponseFormat = RESPONSE_FORMAT,
        countryCodes: string = COUNTRY_CODES
    ): Observable<GeocodingResult[] | undefined> {
        const requestPayload: GeocodingApiRequestPayload = {
            format,
            q: address,
            countrycodes: countryCodes,
        };

        return this.http
            .get<GeocodingApiResponseBody[]>(this.getNominatimGeocodingEndpoints.search, { params: { ...requestPayload } })
            .pipe(
                map((response) => convertGeocodingApiResponseToGeocodingCoordinates(response)),
                catchError(() => throwError(() => ({ type: GeocodingErrorType.Unknown })))
            );
    }
}
