import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Inject, Input } from "@angular/core";
import { UavAppUrls, UAV_APP_URLS } from "@dtm-frontend/shared/map/leaflet-remote-id";
import { DmsCoordinatesUtils, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { LocalComponentStore, StringUtils } from "@dtm-frontend/shared/utils";
import { GeoJSON } from "leaflet";
import { Flight, FlightType } from "../../../../flight/models/flight.models";

interface LeafletFlightsLayerDetailsComponentState {
    flight: Flight | undefined;
    hasExtendedDetails: boolean;
}

@Component({
    selector: "uav-id-shared-lib-leaflet-flights-layer-details[flight]",
    templateUrl: "leaflet-flights-layer-details.component.html",
    styleUrls: ["leaflet-flights-layer-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LeafletFlightsLayerDetailsComponent {
    @Input() public set flight(value: Flight | undefined) {
        this.localStore.patchState({ flight: value });
    }

    @Input() public set hasExtendedDetails(value: BooleanInput) {
        this.localStore.patchState({ hasExtendedDetails: coerceBooleanProperty(value) });
    }

    protected flight$ = this.localStore.selectByKey("flight");
    protected hasExtendedDetails$ = this.localStore.selectByKey("hasExtendedDetails");
    protected FlightType = FlightType;

    constructor(
        private readonly localStore: LocalComponentStore<LeafletFlightsLayerDetailsComponentState>,
        @Inject(UAV_APP_URLS) protected readonly uavAppUrls: UavAppUrls
    ) {
        this.localStore.setState({
            flight: undefined,
            hasExtendedDetails: false,
        });
    }

    protected getCheckinCoordinates(flight: Flight): string {
        const [longitude, latitude] = (flight.routeVolume.flightArea as GeoJSON.Point).coordinates;
        const latitudeDmsWithDirectionString = DmsCoordinatesUtils.convertDmsWithDirectionToString(
            DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(latitude, GeographicCoordinatesType.Latitude)
        );
        const longitudeDmsWithDirectionString = DmsCoordinatesUtils.convertDmsWithDirectionToString(
            DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(longitude, GeographicCoordinatesType.Longitude)
        );

        return `${latitudeDmsWithDirectionString} ${longitudeDmsWithDirectionString}`;
    }

    protected getOperatorProfileLink(operatorId: string | undefined, uavAppUrls: UavAppUrls): string {
        return StringUtils.replaceInTemplate(uavAppUrls.operatorProfile ?? "", { operatorId: operatorId ?? "" });
    }

    protected getPilotProfileLink(pilotId: string | undefined, uavAppUrls: UavAppUrls): string {
        return StringUtils.replaceInTemplate(uavAppUrls.pilotProfile ?? "", { pilotId: pilotId ?? "" });
    }
}
