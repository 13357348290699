import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatSortModule } from "@angular/material/sort";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { ReportSharedModule } from "@dtm-frontend/uav-identification-shared-lib/report";
import { UavIdentificationSharedModule } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { MyInterventionPreviewComponent } from "./components/my-intervention-preview/my-intervention-preview.component";
import { MyInterventionsListContainerComponent } from "./components/my-interventions-list-container/my-interventions-list-container.component";
import { MyInterventionsListFiltersComponent } from "./components/my-interventions-list-filters/my-interventions-list-filters.component";
import { MyInterventionsListComponent } from "./components/my-interventions-list/my-interventions-list.component";
import { MyInterventionsRoutingModule } from "./my-interventions-routing.module";

@NgModule({
    imports: [
        CommonModule,
        LetModule,
        MatDatepickerModule,
        MatInputModule,
        MatMenuModule,
        MatTableModule,
        MatSortModule,
        MyInterventionsRoutingModule,
        PushModule,
        ReactiveFormsModule,
        ReportSharedModule,
        SharedI18nModule,
        SharedUiModule,
        UavIdentificationSharedModule,
    ],
    declarations: [
        MyInterventionPreviewComponent,
        MyInterventionsListComponent,
        MyInterventionsListContainerComponent,
        MyInterventionsListFiltersComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "uavIdClientLibMyInterventions",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class MyInterventionsModule {}
