import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { Network } from "@capacitor/network";
import { AuthState } from "@dtm-frontend/shared/auth";
import { Position, SharedMapEndpoints, SHARED_MAP_ENDPOINTS } from "@dtm-frontend/shared/map";
import { LeafletMapLayerConfig } from "@dtm-frontend/shared/map/leaflet";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DtmToastComponent } from "@dtm-frontend/shared/ui/toast";
import { AnimationUtils, LocalComponentStore, LOCAL_STORAGE, SESSION_STORAGE } from "@dtm-frontend/shared/utils";
import { Flight, FlightActions, FlightState } from "@dtm-frontend/uav-identification-shared-lib/flight";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { DefaultGlobalConfig, IndividualConfig, ToastContainerDirective, ToastrService } from "ngx-toastr";
import { first, map, Subject, takeUntil, tap } from "rxjs";
import { IS_MOBILE_APP } from "../../../shared/shared.tokens";
import { IS_POLICE_HEADQUARTERS_LAYER_ENABLED } from "../../report.tokens";
import { ReportActions } from "../../state/report.actions";
import { ReportState } from "../../state/report.state";
import { FlightsAvailableToastComponent } from "./report-geolocation-toast/flights-available-toast/flights-available-toast.component";
import { FlightsNotAvailableToastComponent } from "./report-geolocation-toast/flights-not-available-toast/flights-not-available-toast.component";
import { RemoteIdInfoToastComponent } from "./report-geolocation-toast/remote-id-info-toast/remote-id-info-toast.component";
import { RemoteIdLocalizationWarningToastComponent } from "./report-geolocation-toast/remote-id-localization-warning-toast/remote-id-localization-warning-toast.component";

interface ReportGeolocationComponentState {
    isGeoLocationDenied: boolean;
    isPositionProcessing: boolean;
    flightToastInfoId: number | undefined;
    isRemoteIdLocalizationWarningDisplayed: boolean;
    isOnline: boolean;
}

export const ACKNOWLEDGE_TOAST_ACTION = "acknowledgeToastAction";
const LOCAL_STORAGE_HAS_SEEN_REMOTE_ID_INFO = "uavId-hasSeenRemoteIdInfo";
const SESSION_STORAGE_HAS_SEEN_REMOTE_ID_LOCALIZATION_WARNING = "uavId-hasSeenRemoteIdILocalizationWarning";
const ROLES_WITH_EXTENDED_DETAILS = [UavIdRole.Officer, UavIdRole.DutyOfficer, UavIdRole.Admin];
const LOCAL_STORAGE_HAS_SEEN_FLIGHTS_HELP_KEY = "uavId-hasSeenFlightsHelp";
const GEOLOCATION_ACCURACY_THRESHOLD_IN_METERS = 50;
const CONFIRM_TOAST_OPTIONS: Partial<IndividualConfig> = {
    disableTimeOut: true,
    closeButton: true,
    tapToDismiss: false,
    easeTime: 0,
};

@UntilDestroy()
@Component({
    selector: "uav-id-client-lib-report-geolocation",
    templateUrl: "report-geolocation.component.html",
    styleUrls: ["report-geolocation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.fadeAnimation()],
    providers: [LocalComponentStore, ToastrService],
})
export class ReportGeolocationComponent {
    @ViewChild(ToastContainerDirective) public set mapInfoToastContainer(value: ToastContainerDirective) {
        this.setupMapInfoToastContainer(value);
    }

    protected readonly flights$ = this.store.select(FlightState.flights).pipe(tap((flights) => this.showFlightsToast(flights)));
    protected readonly areFlightsProcessing$ = this.store.select(FlightState.isProcessing);
    protected readonly isPositionProcessing$ = this.localStore.selectByKey("isPositionProcessing");
    protected readonly userPosition$ = this.store.select(ReportState.userPosition);
    protected readonly isGeoLocationDenied$ = this.localStore.selectByKey("isGeoLocationDenied");
    protected readonly POLICE_HEADQUARTERS_LAYER_CONFIG: LeafletMapLayerConfig = {
        type: "WMS",
        baseUrl: this.sharedMapEndpoints.geoServerEndpoint,
        options: {
            layers: "uav-identification",
            format: "image/png",
            transparent: true,
        },
    };
    protected readonly hasExtendedFlightDetails$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => roles?.some((role) => ROLES_WITH_EXTENDED_DETAILS.includes(role as UavIdRole))));

    private readonly unsubscribeOnIonViewWillLeave = new Subject<void>();

    constructor(
        @Inject(IS_MOBILE_APP) private readonly isMobileApp: boolean,
        private readonly localStore: LocalComponentStore<ReportGeolocationComponentState>,
        @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
        @Inject(SESSION_STORAGE) private readonly sessionStorage: Storage,
        @Inject(SHARED_MAP_ENDPOINTS) private readonly sharedMapEndpoints: SharedMapEndpoints,
        private readonly store: Store,
        private readonly translationHelper: TranslationHelperService,
        private readonly toastService: ToastrService,
        @Inject(IS_POLICE_HEADQUARTERS_LAYER_ENABLED) protected readonly isPoliceHeadquartersLayerEnabled: boolean
    ) {
        this.localStore.setState({
            isGeoLocationDenied: false,
            isPositionProcessing: true,
            flightToastInfoId: undefined,
            isRemoteIdLocalizationWarningDisplayed: false,
            isOnline: false,
        });

        Network.getStatus().then((connectionStatus) => this.localStore.patchState({ isOnline: connectionStatus.connected }));
    }

    public ionViewWillEnter() {
        this.watchNetworkStatusChange();
        this.refreshData();
        this.displayRemoteIdInfoToast();
        this.displayRemoteIdWarningToast();
        this.store.dispatch(new FlightActions.StartFlightsUpdatesWatch());
    }

    // NOTE: Ionic hook required to clean up toasts and subscriptions, since the view might be cached and ngOnDestroy won't be called
    public ionViewWillLeave() {
        this.toastService.clear();
        this.unsubscribeOnIonViewWillLeave.next();
        this.clearNetworkListeners();
        this.localStore.patchState({ isRemoteIdLocalizationWarningDisplayed: false });
        this.store.dispatch(new FlightActions.StopFlightsUpdatesWatch());
    }

    protected onUserPositionUpdated({ latitude, longitude, accuracy }: Position): void {
        this.store.dispatch(new ReportActions.UpdateUserPosition(latitude, longitude, accuracy));
        this.getFlights({ latitude, longitude });

        this.showToastIfPositionIsInaccurate(accuracy);
        this.localStore.patchState({ isPositionProcessing: false });
    }

    protected onGeoLocationDenied(): void {
        this.localStore.patchState({ isGeoLocationDenied: true, isPositionProcessing: false });
        this.displayRemoteIdWarningToast();
    }

    protected refreshData(): void {
        const userPosition = this.store.selectSnapshot(ReportState.userPosition);

        if (!userPosition) {
            return;
        }

        this.getFlights(userPosition);
    }

    private showFlightsToast(flights: Flight[] | undefined): void {
        if (flights === undefined) {
            return;
        }

        const toastId = this.localStore.selectSnapshotByKey("flightToastInfoId");

        if (toastId !== undefined) {
            this.toastService.clear(toastId);
        }

        const areFlightsAvailable = !!flights.length;
        if (!areFlightsAvailable) {
            const flightsNotAvailableToast = this.toastService.info(undefined, undefined, {
                toastComponent: FlightsNotAvailableToastComponent,
                ...CONFIRM_TOAST_OPTIONS,
            });

            this.localStore.patchState({ flightToastInfoId: flightsNotAvailableToast.toastId });

            return;
        }

        if (this.localStorage.getItem(LOCAL_STORAGE_HAS_SEEN_FLIGHTS_HELP_KEY)) {
            return;
        }

        this.localStorage.setItem(LOCAL_STORAGE_HAS_SEEN_FLIGHTS_HELP_KEY, "true");
        const flightsAvailableToast = this.toastService.info(undefined, undefined, {
            toastComponent: FlightsAvailableToastComponent,
            ...CONFIRM_TOAST_OPTIONS,
        });

        this.localStore.patchState({ flightToastInfoId: flightsAvailableToast.toastId });
    }

    private showToastIfPositionIsInaccurate(accuracy: number | undefined): void {
        // NOTE: The lower the value, the more accurate the position is
        if (accuracy === undefined || accuracy <= GEOLOCATION_ACCURACY_THRESHOLD_IN_METERS) {
            return;
        }

        this.translationHelper
            .waitForTranslation("uavIdClientLibReport.reportGeolocation.toastInaccuratePositionLabel")
            .pipe(first(), takeUntil(this.unsubscribeOnIonViewWillLeave), untilDestroyed(this))
            .subscribe((message) => this.toastService.warning(message, undefined, CONFIRM_TOAST_OPTIONS));
    }

    private handleGetFlightsError(): void {
        this.translationHelper
            .waitForTranslation("uavIdClientLibReport.reportGeolocation.getFlightsErrorLabel")
            .pipe(first(), untilDestroyed(this))
            .subscribe((message) => this.toastService.error(message));
    }

    private setupMapInfoToastContainer(toastrContainer: ToastContainerDirective) {
        if (!toastrContainer) {
            return;
        }

        this.toastService.overlayContainer = toastrContainer;
        this.toastService.toastrConfig = { ...DefaultGlobalConfig, toastComponent: DtmToastComponent };
    }

    private getFlights({ latitude, longitude }: Position): void {
        this.store
            .dispatch(new FlightActions.GetFlights(latitude, longitude))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(FlightState.getFlightsError);

                if (error) {
                    this.handleGetFlightsError();

                    return;
                }
            });
    }

    private watchNetworkStatusChange(): void {
        Network.addListener("networkStatusChange", ({ connected }) => {
            if (this.localStore.selectSnapshotByKey("isOnline") === connected) {
                return;
            }

            this.localStore.patchState({ isOnline: connected });

            if (connected) {
                this.refreshData();
            }
        });
    }

    private clearNetworkListeners(): void {
        Network.removeAllListeners();
    }

    private displayRemoteIdInfoToast(): void {
        if (!this.isMobileApp || this.localStorage.getItem(LOCAL_STORAGE_HAS_SEEN_REMOTE_ID_INFO)) {
            return;
        }

        const toast = this.toastService.info(undefined, undefined, {
            toastComponent: RemoteIdInfoToastComponent,
            disableTimeOut: true,
            closeButton: false,
            tapToDismiss: false,
            easeTime: 0,
        });

        toast.onAction.pipe(first(), takeUntil(this.unsubscribeOnIonViewWillLeave), untilDestroyed(this)).subscribe((action) => {
            if (action === ACKNOWLEDGE_TOAST_ACTION) {
                this.localStorage.setItem(LOCAL_STORAGE_HAS_SEEN_REMOTE_ID_INFO, "true");
            }
        });
    }

    private displayRemoteIdWarningToast(): void {
        const { isRemoteIdLocalizationWarningDisplayed, isGeoLocationDenied } = this.localStore.get();

        if (
            !this.isMobileApp ||
            !this.localStorage.getItem(LOCAL_STORAGE_HAS_SEEN_REMOTE_ID_INFO) ||
            this.sessionStorage.getItem(SESSION_STORAGE_HAS_SEEN_REMOTE_ID_LOCALIZATION_WARNING) ||
            isRemoteIdLocalizationWarningDisplayed ||
            !isGeoLocationDenied
        ) {
            return;
        }

        const toast = this.toastService.warning(undefined, undefined, {
            toastComponent: RemoteIdLocalizationWarningToastComponent,
            ...CONFIRM_TOAST_OPTIONS,
        });
        this.localStore.patchState({ isRemoteIdLocalizationWarningDisplayed: true });

        toast.onAction.pipe(first(), takeUntil(this.unsubscribeOnIonViewWillLeave), untilDestroyed(this)).subscribe((action) => {
            if (action === ACKNOWLEDGE_TOAST_ACTION) {
                this.sessionStorage.setItem(SESSION_STORAGE_HAS_SEEN_REMOTE_ID_LOCALIZATION_WARNING, "true");
                this.localStore.patchState({ isRemoteIdLocalizationWarningDisplayed: false });
            }
        });
    }
}
