import { Injectable } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { OfficerInstitutionEntity, OfficerUnitEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { catchError, EMPTY, finalize, tap } from "rxjs";
import {
    AccountDeletionError,
    AuthorizationData,
    ChangePasswordError,
    ChangePasswordErrorType,
    CodeVerificationError,
    RegistrationError,
    ResetPasswordError,
} from "../authorization.models";
import { AuthorizationApiService } from "../services/authorization-api.service";
import { AuthorizationActions } from "./authorization.actions";

export interface AuthorizationStateModel {
    authorizationData: AuthorizationData | undefined;
    registrationVerificationError: CodeVerificationError | undefined;
    registrationError: RegistrationError | undefined;
    officerInstitutions: OfficerInstitutionEntity[];
    officerUnits: OfficerUnitEntity[];
    areOfficerUnitsProcessing: boolean;

    updatePasswordRequestId: string | undefined;
    updatePasswordVerificationError: CodeVerificationError | undefined;
    isUpdatePasswordProcessing: boolean;
    resetPasswordError: ResetPasswordError | undefined;
    changePasswordError: ChangePasswordError | undefined;

    accountDeletionRequestId: string | undefined;
    isAccountDeletionProcessing: boolean;
    accountDeletionError: AccountDeletionError | undefined;
    accountDeletionVerificationError: CodeVerificationError | undefined;
}

const DEFAULT_STATE: AuthorizationStateModel = {
    authorizationData: undefined,
    registrationVerificationError: undefined,
    registrationError: undefined,
    officerInstitutions: [],
    officerUnits: [],
    areOfficerUnitsProcessing: false,

    updatePasswordRequestId: undefined,
    updatePasswordVerificationError: undefined,
    isUpdatePasswordProcessing: false,
    resetPasswordError: undefined,
    changePasswordError: undefined,

    accountDeletionRequestId: undefined,
    isAccountDeletionProcessing: false,
    accountDeletionError: undefined,
    accountDeletionVerificationError: undefined,
};

@State<AuthorizationStateModel>({
    name: "authorization",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class AuthorizationState {
    @Selector()
    public static authorizationData(state: AuthorizationStateModel): AuthorizationData | undefined {
        return state.authorizationData;
    }

    @Selector()
    public static registrationVerificationError(state: AuthorizationStateModel): CodeVerificationError | undefined {
        return state.registrationVerificationError;
    }

    @Selector()
    public static registrationError(state: AuthorizationStateModel): RegistrationError | undefined {
        return state.registrationError;
    }

    @Selector()
    public static officerInstitutions(state: AuthorizationStateModel): OfficerInstitutionEntity[] {
        return state.officerInstitutions;
    }

    @Selector()
    public static officerUnits(state: AuthorizationStateModel): OfficerUnitEntity[] {
        return state.officerUnits;
    }

    @Selector()
    public static areOfficerUnitsProcessing(state: AuthorizationStateModel): boolean {
        return state.areOfficerUnitsProcessing;
    }

    @Selector()
    public static resetPasswordError(state: AuthorizationStateModel): ResetPasswordError | undefined {
        return state.resetPasswordError;
    }

    @Selector()
    public static changePasswordError(state: AuthorizationStateModel): ChangePasswordError | undefined {
        return state.changePasswordError;
    }

    @Selector()
    public static updatePasswordVerificationError(state: AuthorizationStateModel): CodeVerificationError | undefined {
        return state.updatePasswordVerificationError;
    }

    @Selector()
    public static isUpdatePasswordProcessing(state: AuthorizationStateModel): boolean {
        return state.isUpdatePasswordProcessing;
    }

    @Selector()
    public static accountDeletionError(state: AuthorizationStateModel): AccountDeletionError | undefined {
        return state.accountDeletionError;
    }

    @Selector()
    public static accountDeletionVerificationError(state: AuthorizationStateModel): CodeVerificationError | undefined {
        return state.accountDeletionVerificationError;
    }

    @Selector()
    public static isAccountDeletionProcessing(state: AuthorizationStateModel): boolean {
        return state.isAccountDeletionProcessing;
    }

    constructor(private readonly authorizationApi: AuthorizationApiService, private readonly store: Store) {}

    @Action(AuthorizationActions.Register)
    public register(context: StateContext<AuthorizationStateModel>, action: AuthorizationActions.Register) {
        const { phoneNumber, email, password, isOfficerAccount } = action.registrationFormData ?? {};

        return this.authorizationApi.register(action.registrationFormData).pipe(
            tap((registrationResponse) =>
                context.patchState({
                    registrationError: undefined,
                    authorizationData: { phoneNumber, email, password, isOfficerAccount, registrationId: registrationResponse.id },
                })
            ),
            catchError((error) => {
                context.patchState({ registrationError: error });

                return EMPTY;
            })
        );
    }

    @Action(AuthorizationActions.VerifyRegistration)
    public verifyRegistration(context: StateContext<AuthorizationStateModel>, action: AuthorizationActions.VerifyRegistration) {
        return this.authorizationApi.verifyRegistration(action.registrationId, action.requestPayload).pipe(
            tap(() =>
                context.patchState({
                    registrationVerificationError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({ registrationVerificationError: error });

                return EMPTY;
            })
        );
    }

    @Action(AuthorizationActions.ResendRegistrationVerificationCode)
    public resendRegistrationVerificationCode(
        context: StateContext<AuthorizationStateModel>,
        action: AuthorizationActions.ResendRegistrationVerificationCode
    ) {
        return this.authorizationApi.resendRegistrationVerificationCode(action.registrationId).pipe(
            tap(() =>
                context.patchState({
                    registrationVerificationError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({ registrationVerificationError: error });

                return EMPTY;
            })
        );
    }

    @Action(AuthorizationActions.GetOfficerInstitutions)
    public getOfficerInstitutions(context: StateContext<AuthorizationStateModel>) {
        return this.authorizationApi.getOfficerInstitutions().pipe(
            tap((officerInstitutions) => {
                context.patchState({ registrationError: undefined, officerInstitutions });
            }),
            catchError((error) => {
                context.patchState({ registrationError: error });

                return EMPTY;
            })
        );
    }

    @Action(AuthorizationActions.GetOfficerUnits)
    public getOfficerUnits(context: StateContext<AuthorizationStateModel>, { institutionId }: AuthorizationActions.GetOfficerUnits) {
        context.patchState({ areOfficerUnitsProcessing: true, officerUnits: [], registrationError: undefined });

        return this.authorizationApi.getOfficerUnits(institutionId).pipe(
            tap((officerUnits) => context.patchState({ officerUnits })),
            catchError((error) => {
                context.patchState({ registrationError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ areOfficerUnitsProcessing: false }))
        );
    }

    @Action(AuthorizationActions.ResetPassword)
    public resetPassword(context: StateContext<AuthorizationStateModel>, { email }: AuthorizationActions.ResetPassword) {
        context.patchState({ isUpdatePasswordProcessing: true, resetPasswordError: undefined, updatePasswordRequestId: undefined });

        return this.authorizationApi.resetPassword(email).pipe(
            tap((resetPasswordResponse) =>
                context.patchState({ updatePasswordRequestId: resetPasswordResponse.passwordModificationRequestId })
            ),
            catchError((error) => {
                context.patchState({ resetPasswordError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isUpdatePasswordProcessing: false }))
        );
    }

    @Action(AuthorizationActions.ChangePassword)
    public changePassword(context: StateContext<AuthorizationStateModel>) {
        context.patchState({ isUpdatePasswordProcessing: true, changePasswordError: undefined, updatePasswordRequestId: undefined });

        const userId = this.store.selectSnapshot(AuthState.userId);
        if (!userId) {
            context.patchState({ changePasswordError: { type: ChangePasswordErrorType.Unknown } });

            return;
        }

        return this.authorizationApi.changePassword(userId).pipe(
            tap((changePasswordResponse) =>
                context.patchState({ updatePasswordRequestId: changePasswordResponse.passwordModificationRequestId })
            ),
            catchError((error) => {
                context.patchState({ changePasswordError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isUpdatePasswordProcessing: false }))
        );
    }

    @Action(AuthorizationActions.UpdatePassword)
    public updatePassword(context: StateContext<AuthorizationStateModel>, { data }: AuthorizationActions.UpdatePassword) {
        context.patchState({ isUpdatePasswordProcessing: true, updatePasswordVerificationError: undefined });
        const { updatePasswordRequestId } = context.getState();

        if (!updatePasswordRequestId) {
            return;
        }

        return this.authorizationApi.updatePassword(updatePasswordRequestId, data).pipe(
            catchError((error) => {
                context.patchState({ updatePasswordVerificationError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isUpdatePasswordProcessing: false }))
        );
    }

    @Action(AuthorizationActions.ResendUpdatePasswordVerificationCode)
    public resendUpdatePasswordVerificationCode(context: StateContext<AuthorizationStateModel>) {
        context.patchState({ updatePasswordVerificationError: undefined });
        const { updatePasswordRequestId } = context.getState();

        if (!updatePasswordRequestId) {
            return;
        }

        return this.authorizationApi.resendUpdatePasswordVerificationCode(updatePasswordRequestId).pipe(
            catchError((error) => {
                context.patchState({ updatePasswordVerificationError: error });

                return EMPTY;
            })
        );
    }

    @Action(AuthorizationActions.RequestAccountDeletion)
    public requestAccountDeletion(context: StateContext<AuthorizationStateModel>) {
        context.patchState({ isAccountDeletionProcessing: true, accountDeletionError: undefined, accountDeletionRequestId: undefined });
        const userId = this.store.selectSnapshot(AuthState.userId);

        if (!userId) {
            return;
        }

        return this.authorizationApi.requestAccountDeletion(userId).pipe(
            tap((response) => context.patchState({ accountDeletionRequestId: response.deleteAccountRequestId })),
            catchError((error) => {
                context.patchState({ accountDeletionError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isAccountDeletionProcessing: false }))
        );
    }

    @Action(AuthorizationActions.ConfirmAccountDeletion)
    public confirmAccountDeletion(
        context: StateContext<AuthorizationStateModel>,
        { verificationCode }: AuthorizationActions.ConfirmAccountDeletion
    ) {
        context.patchState({ isAccountDeletionProcessing: true, accountDeletionVerificationError: undefined });
        const { accountDeletionRequestId } = context.getState();

        if (!accountDeletionRequestId) {
            return;
        }

        return this.authorizationApi.confirmAccountDeletion(accountDeletionRequestId, verificationCode).pipe(
            catchError((error) => {
                context.patchState({ accountDeletionVerificationError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isAccountDeletionProcessing: false }))
        );
    }

    @Action(AuthorizationActions.ResendAccountDeletionVerificationCode)
    public resendAccountDeletionVerificationCode(context: StateContext<AuthorizationStateModel>) {
        context.patchState({ accountDeletionVerificationError: undefined });
        const { accountDeletionRequestId } = context.getState();

        if (!accountDeletionRequestId) {
            return;
        }

        return this.authorizationApi.resendAccountDeletionVerificationCode(accountDeletionRequestId).pipe(
            catchError((error) => {
                context.patchState({ accountDeletionVerificationError: error });

                return EMPTY;
            })
        );
    }
}
