import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { catchError, Observable, throwError } from "rxjs";
import {
    AccountDeletionErrorType,
    ChangePasswordErrorType,
    RegistrationErrorType,
    RegistrationFormData,
    UpdatePasswordData,
} from "../authorization.models";
import { AuthorizationEndpoints, AUTHORIZATION_ENDPOINTS } from "../authorization.tokens";
import {
    AccountDeletionResponseBody,
    convertCodeVerificationErrorResponseBodyToCodeVerificationError,
    convertCodeVerificationErrorResponseBodyToCodeVerificationErrorForResend,
    convertRegistrationErrorResponseBodyToRegistrationError,
    convertRegistrationFormDataToRegistrationRequestPayload,
    convertResetPasswordErrorResponseBodyToResetPasswordError,
    GetOfficerInstitutionsResponseBody,
    GetOfficerUnitsResponseBody,
    ModifyPasswordResponseBody,
    RegistrationResponseBody,
    RegistrationVerificationRequestPayload,
} from "./authorization-api.converters";

@Injectable()
export class AuthorizationApiService {
    constructor(private readonly http: HttpClient, @Inject(AUTHORIZATION_ENDPOINTS) private readonly endpoints: AuthorizationEndpoints) {}

    public register(registrationFormData: RegistrationFormData): Observable<RegistrationResponseBody> {
        return this.http
            .post<RegistrationResponseBody>(
                this.endpoints.register,
                convertRegistrationFormDataToRegistrationRequestPayload(registrationFormData)
            )
            .pipe(catchError((errorResponse) => throwError(() => convertRegistrationErrorResponseBodyToRegistrationError(errorResponse))));
    }

    public verifyRegistration(registrationId: string, requestPayload: RegistrationVerificationRequestPayload): Observable<void> {
        return this.http
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.verifyRegistration, { registrationId }), requestPayload)
            .pipe(
                catchError((errorResponse) =>
                    throwError(() => convertCodeVerificationErrorResponseBodyToCodeVerificationError(errorResponse))
                )
            );
    }

    public resendRegistrationVerificationCode(registrationId: string): Observable<void> {
        return this.http
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.resendRegistrationVerificationCode, { registrationId }), {})
            .pipe(
                catchError((errorResponse) =>
                    throwError(() => convertCodeVerificationErrorResponseBodyToCodeVerificationErrorForResend(errorResponse))
                )
            );
    }

    public getOfficerInstitutions(): Observable<GetOfficerInstitutionsResponseBody> {
        return this.http.get<GetOfficerInstitutionsResponseBody>(this.endpoints.getOfficerInstitutions).pipe(
            catchError(() =>
                throwError(() => ({
                    type: RegistrationErrorType.Unknown,
                }))
            )
        );
    }

    public getOfficerUnits(institutionId: string): Observable<GetOfficerUnitsResponseBody> {
        return this.http
            .get<GetOfficerUnitsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getOfficerUnits, { institutionId }))
            .pipe(catchError(() => throwError(() => ({ type: RegistrationErrorType.Unknown }))));
    }

    public resetPassword(email: string): Observable<ModifyPasswordResponseBody> {
        return this.http
            .post<ModifyPasswordResponseBody>(this.endpoints.resetPassword, { email })
            .pipe(
                catchError((errorResponse) => throwError(() => convertResetPasswordErrorResponseBodyToResetPasswordError(errorResponse)))
            );
    }

    public changePassword(userId: string): Observable<ModifyPasswordResponseBody> {
        return this.http
            .post<ModifyPasswordResponseBody>(StringUtils.replaceInTemplate(this.endpoints.changePassword, { userId }), null)
            .pipe(catchError(() => throwError(() => ({ type: ChangePasswordErrorType.Unknown }))));
    }

    public updatePassword(requestId: string, data: UpdatePasswordData): Observable<void> {
        return this.http
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updatePassword, { requestId }), data)
            .pipe(
                catchError((errorResponse) =>
                    throwError(() => convertCodeVerificationErrorResponseBodyToCodeVerificationError(errorResponse))
                )
            );
    }

    public resendUpdatePasswordVerificationCode(requestId: string): Observable<void> {
        return this.http
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.resendUpdatePasswordVerificationCode, { requestId }), null)
            .pipe(
                catchError((errorResponse) =>
                    throwError(() => convertCodeVerificationErrorResponseBodyToCodeVerificationErrorForResend(errorResponse))
                )
            );
    }

    public requestAccountDeletion(userId: string): Observable<AccountDeletionResponseBody> {
        return this.http
            .post<AccountDeletionResponseBody>(StringUtils.replaceInTemplate(this.endpoints.requestAccountDeletion, { userId }), null)
            .pipe(catchError(() => throwError(() => ({ type: AccountDeletionErrorType.Unknown }))));
    }

    public confirmAccountDeletion(requestId: string, code: string): Observable<void> {
        return this.http
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.confirmAccountDeletion, { requestId }), { code })
            .pipe(
                catchError((errorResponse) =>
                    throwError(() => convertCodeVerificationErrorResponseBodyToCodeVerificationError(errorResponse))
                )
            );
    }

    public resendAccountDeletionVerificationCode(requestId: string): Observable<void> {
        return this.http
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.resendAccountDeletionVerificationCode, { requestId }), null)
            .pipe(
                catchError((errorResponse) =>
                    throwError(() => convertCodeVerificationErrorResponseBodyToCodeVerificationErrorForResend(errorResponse))
                )
            );
    }
}
