import { isPlatformBrowser } from "@angular/common";
import { Directive, ElementRef, Inject, Input, PLATFORM_ID } from "@angular/core";

const complexIconNames = [
    "complex-altitude",
    "complex-altitude-range",
    "complex-bottom-height",
    "complex-cylinder-bottom",
    "complex-cylinder-dash-fill-top-down",
    "complex-cylinder-fill",
    "complex-cylinder-fill-top-down",
    "complex-cylinder-point-center",
    "complex-cylinder-point-left",
    "complex-cylinder-point-right",
    "complex-cylinder-top",
    "complex-height",
    "complex-helicopter",
    "complex-leaving-area",
    "complex-mail-notification",
    "complex-mission-conflict",
    "complex-prism-bottom",
    "complex-prism-top",
    "complex-radius",
    "complex-team-fill",
    "complex-team-fill-less",
    "complex-text-block",
    "complex-text-block-off",
    "complex-text-block-on",
    "complex-top-height",
    "complex-waypoint-edit",
];

export const iconNames = [
    ...complexIconNames,
    // NOTE: All available icons should be listed below (sorted alphabetically using https://www.online-utility.org/text/sort.jsp)
    "add",
    "add-circle",
    "airstrip",
    "alarm-warning",
    "alarm-warning-fill",
    "alert",
    "alert-fill",
    "align-center",
    "align-justify",
    "align-left",
    "align-right",
    "archive",
    "archive-fill",
    "arrow-down",
    "arrow-down-2",
    "arrow-down-3",
    "arrow-down-circle-fill",
    "arrow-go",
    "arrow-go-back",
    "arrow-go-forward",
    "arrow-left",
    "arrow-left-fill",
    "arrow-left-right",
    "arrow-left-right-2",
    "arrow-right",
    "arrow-right-2",
    "arrow-up",
    "arrow-up-2",
    "arrow-up-3",
    "arrow-up-circle-fill",
    "arrow-up-double",
    "arrow-up-down-2",
    "arrow-up-down-left",
    "arrow-up-single",
    "arrow-up-triple",
    "article",
    "attachment",
    "ball-pen",
    "bank-card",
    "barricade-fill",
    "base-station",
    "base-station-fill",
    "bold",
    "booklet",
    "bookmark",
    "bookmark-fill",
    "briefcase",
    "bubble-chart",
    "building",
    "calendar-check-fill",
    "calendar-close-fill",
    "calendar-event",
    "calendar-event-fill",
    "camera",
    "camera-switch-line",
    "car",
    "chart",
    "chat",
    "check",
    "checkbox",
    "checkbox-blank",
    "checkbox-circle",
    "checkbox-circle-fill",
    "checkbox-indeterminate",
    "checkbox-x",
    "child",
    "circle",
    "close",
    "close-circle",
    "close-circle-fill",
    "coins",
    "collage",
    "color",
    "color-middle",
    "color-pattern",
    "community",
    "conflict-arrows",
    "conflict-flash",
    "contract",
    "cursor",
    "cylinder-dash",
    "cylinder-down",
    "cylinder-fill",
    "cylinder-fill-top-down",
    "cylinder-line",
    "cylinder-question",
    "cylinder-top",
    "dashboard",
    "dashboard-2",
    "delete-bin",
    "diploma",
    "dot-down",
    "dot-left-small",
    "dot-middle",
    "dot-middle-small",
    "dot-right-small",
    "download",
    "draft",
    "drag-drop",
    "drag-move",
    "draw-arrow-straight",
    "drawer-close",
    "drawer-open",
    "drone",
    "drone-hidden",
    "drone-swarm",
    "drop",
    "earth",
    "edit",
    "edit-fill",
    "eject",
    "elevation",
    "equalizer-fill",
    "error-warning",
    "error-warning-fill",
    "exchange-funds-line",
    "expanded-up-down",
    "external-link",
    "eye",
    "eye-off",
    "file",
    "file-copy",
    "file-details",
    "file-edit",
    "file-forbid",
    "file-info",
    "file-list",
    "file-mark",
    "file-paper",
    "file-shield",
    "file-text",
    "file-unknow",
    "file-user",
    "filter",
    "finish",
    "fire",
    "first-aid-kit-fill",
    "first-aid-kit-line",
    "flag",
    "flag-fill",
    "flashlight-filled",
    "flashlight-outline",
    "flight-land",
    "flight-takeoff",
    "flyeye",
    "focus",
    "focus-2",
    "focus-2-fill",
    "forbid",
    "function",
    "gamepad",
    "global",
    "global-poland",
    "google",
    "group",
    "hand",
    "helicopter",
    "helicopter-lpr",
    "helicopter-lpr-bg",
    "helicopter-lpr-fill",
    "hems",
    "hems-emr",
    "home",
    "home-2",
    "hourglass-fill",
    "id-card",
    "information",
    "information-fill",
    "italic",
    "leaving-area-path",
    "leaving-area-track",
    "lifebuoy",
    "line-down",
    "line-up",
    "lines",
    "lines-right",
    "list-ordered",
    "list-unordered",
    "loader",
    "lock",
    "lock-unlock",
    "logout-circle",
    "logout-rectangle",
    "magic",
    "mail",
    "mail-close",
    "mail-envelope",
    "mail-error",
    "mail-lock",
    "mail-notification-1",
    "mail-notification-2",
    "mail-open",
    "mail-send",
    "mail-send-fill",
    "mail-tick",
    "mail-unread",
    "map",
    "map-info",
    "map-pin",
    "map-pin-accepted",
    "map-pin-fill",
    "map-pin-time",
    "map-unpin",
    "map-unpin-fill",
    "markpen",
    "markup",
    "menu",
    "menu-fold",
    "menu-unfold",
    "moon",
    "more",
    "north",
    "notification",
    "notification-2-fill",
    "nurse",
    "parent",
    "pause-circle",
    "pause-circle-fill",
    "pen-nib",
    "pen-nib-land",
    "pen-nib-line",
    "pen-nib-oval",
    "pen-nib-prism",
    "pen-nib-takeoff",
    "pencil",
    "pencil-ruler",
    "phone",
    "pin-distance",
    "pin-off",
    "plane",
    "plane-airport-fill",
    "play-circle",
    "play-fill",
    "polygon",
    "postpone",
    "prism",
    "prism-down",
    "prism-top",
    "profile",
    "ruler",
    "question",
    "radio-button",
    "radio-button-blank",
    "radius",
    "rainy",
    "rectangle",
    "refresh",
    "reply",
    "rest-time",
    "road-map",
    "route",
    "sailboat",
    "save",
    "scales-2",
    "school",
    "science",
    "scissors",
    "search",
    "segment",
    "send-plane-fill",
    "settings",
    "shape-cylinder",
    "shape-cylinder-3d",
    "shape-freehand",
    "shape-irregular",
    "shape-line",
    "shape-prism",
    "shapes",
    "share",
    "shield",
    "shield-check",
    "shield-cross",
    "shield-star",
    "side-bar",
    "sign-prohibition",
    "signal-tower-fill",
    "size-thick",
    "size-thin",
    "sms-phone",
    "sms-tick",
    "speed-up-fill",
    "spy",
    "spy-fill",
    "stack-fill",
    "stack-line",
    "stamp",
    "star",
    "star-badge",
    "star-fill",
    "status-online-offline",
    "sticky-note",
    "strikethrough",
    "student",
    "subtract",
    "sun",
    "sun-cloudy",
    "swap-box",
    "task",
    "team",
    "team-fill",
    "team-fill-complex-1a",
    "team-fill-complex-1b",
    "teenager",
    "temp-cold",
    "text",
    "text-block-complex-1a",
    "text-block-complex-1b",
    "text-block-complex-2a",
    "text-block-complex-2b",
    "thumb-down",
    "thumb-up",
    "time",
    "timer-flash-fill",
    "todo",
    "tools",
    "tracker",
    "treasure-map",
    "treasure-map-fill",
    "tree",
    "tree-fill",
    "underline",
    "upload",
    "user",
    "user-add",
    "user-follow",
    "user-follow-fill",
    "user-help",
    "user-settings",
    "user-star",
    "waypoint",
    "wifi-off",
    "window-check-fill",
    "window-fill",
    "windy",
    "zoom-in-line",
    // NOTE: All available icons should be listed above (sorted alphabetically using https://www.online-utility.org/text/sort.jsp)
] as const;

export type IconName = (typeof iconNames)[number];

@Directive({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "dtm-ui-icon[name]",
})
export class IconDirective {
    @Input() public set name(value: IconName | undefined) {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        const currentIconClass = this.getCurrentIconClass();

        if (currentIconClass) {
            this.iconElement.nativeElement.classList.remove("iconfont", currentIconClass);
        }

        if (value) {
            this.iconElement.nativeElement.classList.add("iconfont", `icon-${value}`);
        }
    }

    constructor(private readonly iconElement: ElementRef<HTMLElement>, @Inject(PLATFORM_ID) private platformId: string) {}

    private getCurrentIconClass(): string | undefined {
        let iconClass;

        this.iconElement.nativeElement.classList.forEach((iconToken) => {
            if (iconToken.startsWith("icon-")) {
                iconClass = iconToken;
            }
        });

        return iconClass;
    }
}
