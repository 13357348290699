import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { LeafletMapModule } from "@dtm-frontend/shared/map/leaflet";
import { LeafletRemoteIdModule } from "@dtm-frontend/shared/map/leaflet-remote-id";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { UavIdentificationSharedModule } from "../shared/uav-identification-shared.module";
import { InterventionNoteDialogComponent } from "./components/intervention-note-dialog/intervention-note-dialog.component";
import { ReportGeoZoneDialogComponent } from "./components/report-geo-zone-dialog/report-geo-zone-dialog.component";
import { ReportPreviewDetailsComponent } from "./components/report-preview-details/report-preview-details.component";
import { ReportPreviewInterventionNoteComponent } from "./components/report-preview-intervention-note/report-preview-intervention-note.component";
import { ReportPreviewMapComponent } from "./components/report-preview-map/report-preview-map.component";
import { ReportPreviewOfficerComponent } from "./components/report-preview-officer/report-preview-officer.component";
import { ReportPreviewUserComponent } from "./components/report-preview-user/report-preview-user.component";
import { ReportPreviewZonesInfoComponent } from "./components/report-preview-zones-info/report-preview-zones-info.component";
import { ReportUavAreaComponent } from "./components/report-uav-area/report-uav-area.component";
import { ReportInterventionNoteDialogService } from "./services/report-intervention-note-dialog.service";
import { ReportManagementApiService } from "./services/report-management-api.service";

@NgModule({
    imports: [
        CommonModule,
        LeafletMapModule,
        LeafletRemoteIdModule,
        LetModule,
        MatDialogModule,
        MatInputModule,
        MatProgressSpinnerModule,
        PushModule,
        ReactiveFormsModule,
        SharedI18nModule,
        SharedUiModule,
        UavIdentificationSharedModule,
    ],
    declarations: [
        InterventionNoteDialogComponent,
        ReportGeoZoneDialogComponent,
        ReportPreviewDetailsComponent,
        ReportPreviewInterventionNoteComponent,
        ReportPreviewMapComponent,
        ReportPreviewOfficerComponent,
        ReportPreviewUserComponent,
        ReportPreviewZonesInfoComponent,
        ReportUavAreaComponent,
    ],
    providers: [
        ReportInterventionNoteDialogService,
        ReportManagementApiService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "uavIdSharedLibReport",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [
        ReportGeoZoneDialogComponent,
        ReportPreviewDetailsComponent,
        ReportPreviewInterventionNoteComponent,
        ReportPreviewMapComponent,
        ReportPreviewOfficerComponent,
        ReportPreviewUserComponent,
        ReportPreviewZonesInfoComponent,
        ReportUavAreaComponent,
    ],
})
export class ReportSharedModule {
    public static forTest(): ModuleWithProviders<ReportSharedModule> {
        return {
            ngModule: ReportSharedModule,
            providers: [
                {
                    provide: ReportManagementApiService,
                    useValue: null,
                },
            ],
        };
    }
}
