import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";

@Pipe({
    name: "grcLevel",
})
export class GrcLevelPipe implements PipeTransform {
    constructor(private readonly translocoService: TranslocoService) {}

    public transform(grc: number | undefined, isIntrinsic: boolean = false): string | undefined {
        if (grc === undefined || grc === null) {
            return undefined;
        }

        if (isIntrinsic) {
            return this.translocoService.translate("dtmUi.intrinsicGrcLabel", { value: grc });
        }

        return this.translocoService.translate("dtmUi.grcLabel", { value: grc });
    }
}
