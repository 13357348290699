import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { FaqComponent } from "./components/faq/faq.component";
import { HelpContainerComponent } from "./components/help-container/help-container.component";
import { HowToReportComponent } from "./components/how-to-report/how-to-report.component";

@NgModule({
    imports: [
        CommonModule,
        LetModule,
        NgOptimizedImage,
        PushModule,
        RouterLink,
        RouterLinkActive,
        RouterOutlet,
        SharedI18nModule,
        SharedUiModule,
    ],
    declarations: [FaqComponent, HelpContainerComponent, HowToReportComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "uavIdClientLibHelp",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [HowToReportComponent],
})
export class HelpModule {}
