import { Injectable } from "@angular/core";
import { Position } from "@dtm-frontend/shared/map";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, EMPTY, finalize, tap } from "rxjs";
import { ReportError, ReportIdentity } from "../models/report.model";
import { ReportResponseBody } from "../services/report-api.converters";
import { ReportApiService } from "../services/report-api.service";
import { ReportActions } from "./report.actions";

export interface ReportStateModel {
    isReportProcessing: boolean;
    reportError: ReportError | undefined;
    submittedReport: ReportResponseBody | undefined;
    userPosition: Position | undefined;
    identity: Partial<ReportIdentity> | undefined;
}

const defaultState: ReportStateModel = {
    isReportProcessing: false,
    reportError: undefined,
    submittedReport: undefined,
    userPosition: undefined,
    identity: undefined,
};

@State<ReportStateModel>({
    name: "report",
    defaults: defaultState,
})
@Injectable()
export class ReportState {
    @Selector()
    public static isReportProcessing(state: ReportStateModel): boolean {
        return state.isReportProcessing;
    }

    @Selector()
    public static reportError(state: ReportStateModel): ReportError | undefined {
        return state.reportError;
    }

    @Selector()
    public static submittedReport(state: ReportStateModel): ReportResponseBody | undefined {
        return state.submittedReport;
    }

    @Selector()
    public static userPosition(state: ReportStateModel): Position | undefined {
        return state.userPosition;
    }

    @Selector()
    public static identity(state: ReportStateModel): Partial<ReportIdentity> | undefined {
        return state.identity;
    }

    constructor(private readonly reportApi: ReportApiService) {}

    @Action(ReportActions.UpdateUserPosition)
    public updateUserPosition(context: StateContext<ReportStateModel>, action: ReportActions.UpdateUserPosition) {
        context.patchState({
            userPosition: {
                latitude: action.latitude,
                longitude: action.longitude,
                accuracy: action.accuracy,
            },
        });
    }

    @Action(ReportActions.SendReport)
    public sendReport(context: StateContext<ReportStateModel>, action: ReportActions.SendReport) {
        context.patchState({ isReportProcessing: true, reportError: undefined });

        return this.reportApi.sendReport(action.reportForm).pipe(
            tap((report) => context.patchState({ submittedReport: report })),
            catchError((error) => {
                context.patchState({ reportError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isReportProcessing: false }))
        );
    }

    @Action(ReportActions.VerifyIdentity)
    public verifyIdentity(context: StateContext<ReportStateModel>, action: ReportActions.VerifyIdentity) {
        context.patchState({ identity: { ...(context.getState().identity ?? {}), phoneNumber: action.phoneNumber } });

        return this.reportApi.verifyIdentity(action.phoneNumber).pipe(
            tap((identity) =>
                context.patchState({
                    reportError: undefined,
                    identity,
                })
            ),
            catchError((error) => {
                context.patchState({ reportError: error });

                return EMPTY;
            })
        );
    }

    @Action(ReportActions.SendIdentityVerificationCode)
    public sendIdentityVerificationCode(context: StateContext<ReportStateModel>, action: ReportActions.SendIdentityVerificationCode) {
        return this.reportApi.sendIdentityVerificationCode(action.verificationId, action.code).pipe(
            tap(() =>
                context.patchState({
                    reportError: undefined,
                    identity: { ...(context.getState().identity ?? {}), isVerified: true },
                })
            ),
            catchError((error) => {
                context.patchState({ reportError: error });

                return EMPTY;
            })
        );
    }

    @Action(ReportActions.ResendIdentityVerificationCode)
    public resendIdentityVerificationCode(context: StateContext<ReportStateModel>, action: ReportActions.ResendIdentityVerificationCode) {
        return this.reportApi.resendIdentityVerificationCode(action.verificationId).pipe(
            tap(() => context.patchState({ reportError: undefined })),
            catchError((error) => {
                context.patchState({ reportError: error });

                return EMPTY;
            })
        );
    }

    @Action(ReportActions.ClearIdentity)
    public clearIdentity(context: StateContext<ReportStateModel>) {
        context.patchState({ identity: undefined });
    }
}
