import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PASSWORD_PATTERN } from "@dtm-frontend/shared/auth";
import { requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";
import { AnimationUtils, RxjsUtils, TERMS_OF_USE_URL } from "@dtm-frontend/shared/utils";
import { OfficerInstitutionEntity, OfficerUnitEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, first, map, switchMap, tap } from "rxjs";
import { RegistrationError, RegistrationErrorType, RegistrationFormData } from "../../authorization.models";
import { AuthorizationActions } from "../../state/authorization.actions";
import { AuthorizationState } from "../../state/authorization.state";

const EMAIL_VERIFICATION_URL = "/email-verification";
const PHONE_VERIFICATION_URL = "/phone-verification";
const TEXT_MAX_LENGTH = 256;

@UntilDestroy()
@Component({
    selector: "uav-id-client-lib-registration",
    templateUrl: "registration.component.html",
    styleUrls: ["registration.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class RegistrationComponent {
    protected readonly officerUnits$ = this.store.select(AuthorizationState.officerUnits);
    protected readonly areOfficerUnitsProcessing$ = this.store.select(AuthorizationState.areOfficerUnitsProcessing);

    protected registrationForm: UntypedFormGroup;
    protected readonly firstNameControl = new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(TEXT_MAX_LENGTH),
        Validators.pattern(/\S+/),
    ]);
    protected readonly lastNameControl = new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(TEXT_MAX_LENGTH),
        Validators.pattern(/\S+/),
    ]);
    protected readonly emailControl = new UntypedFormControl(null, [Validators.required, Validators.email]);
    protected readonly phoneNumberControl = new UntypedFormControl(null, requiredValidForSmsPhoneNumberValidator);
    protected readonly passwordControl = new UntypedFormControl(null, {
        validators: [Validators.required, Validators.pattern(PASSWORD_PATTERN)],
        updateOn: "blur",
    });
    protected readonly isDataConfirmedControl = new UntypedFormControl(null, [Validators.requiredTrue]);
    protected readonly officerUnitControl = new UntypedFormControl({ value: null, disabled: true }, [Validators.required]);
    protected readonly areTermsOfUseAcceptedControl = new FormControl<boolean>(false, [Validators.requiredTrue]);

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        @Inject(TERMS_OF_USE_URL) protected readonly termsOfUseUrl: string,
        private readonly translocoService: TranslocoService,
        private readonly toastService: ToastrService
    ) {
        this.store.dispatch(new AuthorizationActions.GetOfficerInstitutions());

        this.registrationForm = new UntypedFormGroup({
            firstName: this.firstNameControl,
            lastName: this.lastNameControl,
            email: this.emailControl,
            phoneNumber: this.phoneNumberControl,
            password: this.passwordControl,
            isDataConfirmed: this.isDataConfirmedControl,
            officerUnit: this.officerUnitControl,
            areTermsOfUseAccepted: this.areTermsOfUseAcceptedControl,
        });

        this.watchForEmailChanges();
    }

    protected submit() {
        this.registrationForm.markAllAsTouched();

        if (this.registrationForm.invalid) {
            return;
        }

        const data: RegistrationFormData = { ...this.registrationForm.value, isOfficerAccount: !!this.officerUnitControl.value };

        this.store
            .dispatch(new AuthorizationActions.Register(data))
            .pipe(
                first(),
                tap(() => {
                    const error = this.store.selectSnapshot(AuthorizationState.registrationError);

                    if (error) {
                        this.handleErrorMessage(error);

                        return;
                    }

                    const authorizationData = this.store.selectSnapshot(AuthorizationState.authorizationData);

                    this.router
                        .navigateByUrl(authorizationData?.isOfficerAccount ? EMAIL_VERIFICATION_URL : PHONE_VERIFICATION_URL)
                        .catch(console.error);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected compareOfficerUnits(unit1: OfficerUnitEntity, unit2: OfficerUnitEntity): boolean {
        return unit1?.id === unit2?.id;
    }

    private watchForEmailChanges(): void {
        this.emailControl.valueChanges
            .pipe(
                map((email) => {
                    const officerInstitution = this.getOfficerInstitution(email);
                    const isOfficerAccount = this.emailControl.valid && !!officerInstitution;

                    this.toggleControl(isOfficerAccount, this.officerUnitControl);

                    return officerInstitution;
                }),
                RxjsUtils.filterFalsy(),
                distinctUntilChanged(),
                switchMap((officerInstitution) => this.store.dispatch(new AuthorizationActions.GetOfficerUnits(officerInstitution.id))),
                tap(() => {
                    const error = this.store.selectSnapshot(AuthorizationState.registrationError);

                    if (!error) {
                        return;
                    }

                    this.handleErrorMessage(error);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private handleErrorMessage(error: RegistrationError): void {
        let errorMessageKey: string;

        switch (error?.type) {
            case RegistrationErrorType.Unknown:
                errorMessageKey = "uavIdClientLibAuth.registration.unknownError";
                break;
            case RegistrationErrorType.EmailAlreadyExists: {
                errorMessageKey = "uavIdClientLibAuth.registration.emailAlreadyExistsError";
                break;
            }
            case RegistrationErrorType.EmailInvalid: {
                errorMessageKey = "uavIdClientLibAuth.registration.emailInvalidError";
                break;
            }
            case RegistrationErrorType.PhoneNumberAlreadyExists: {
                errorMessageKey = "uavIdClientLibAuth.registration.phoneNumberAlreadyExistsError";
                break;
            }
            case RegistrationErrorType.PhoneNumberInvalid: {
                errorMessageKey = "uavIdClientLibAuth.registration.phoneNumberInvalidError";
                break;
            }
        }

        const errorMessage = this.translocoService.translate(errorMessageKey);

        this.toastService.error(errorMessage);
    }

    private getOfficerInstitution(email: string): OfficerInstitutionEntity | undefined {
        if (!email) {
            return undefined;
        }

        const providedEmailDomain = email.slice(email.indexOf("@") + 1);

        return this.store
            .selectSnapshot(AuthorizationState.officerInstitutions)
            .find((institution) => institution.emailDomain === providedEmailDomain);
    }

    private toggleControl(shouldBeEnabled: boolean, control: AbstractControl): void {
        if (shouldBeEnabled && control.disabled) {
            control.enable();

            return;
        }

        if (!shouldBeEnabled && control.enabled) {
            control.reset();
            control.disable();
        }
    }
}
